import React, { createContext, useEffect, useState } from 'react'
import { getCSRFToken } from 'services/search'

export const CSRFTokenContext = createContext()

function CSRFTokenProvider({ children }) {
  const [CSRFToken, setCSRFToken] = useState('')

  const getToken = async () => {
    const res = await getCSRFToken()
    setCSRFToken(res.csrfToken)
  }

  useEffect(() => {
    getToken()
  }, [])

  return (
    <CSRFTokenContext.Provider value={{CSRFToken}}>
      {children}
    </CSRFTokenContext.Provider>
  )
}

export default CSRFTokenProvider
