import React from 'react'
import './Loader.css'
import { t, getCurrentLanguage } from '../../i18n/config'

export const Loader = ({ fade }) => {

    const loadingTextArray = t('searching').split('');
    const hideNations = getCurrentLanguage() === 'zh' ? { opacity: 0, marginTop: -60 } : { opacity: 1, marginTop: 0 };

    return (
        <div className={fade ? 'page-background fade-in' : "page-background"}>
            <div className="loader">
                <div className={"lhs " + getCurrentLanguage()}>
                    <img src="/sepia-un-logo.png" className='footer-logo' alt='footer-logo' />
                    <div className={"un-caption " + getCurrentLanguage()}>
                        <h2>{t('united')}</h2>
                        <h2 style={hideNations}>{t('nations')}</h2>
                    </div>
                </div>
                <div className={'rhs'}>
                    {loadingTextArray.length > 0 && loadingTextArray?.map((elt, index) => {
                        return <span key={elt + '_X' + index} style={{ '--i': index }}>{elt}</span>
                    })}
                </div>

            </div>
        </div>
    )
}
