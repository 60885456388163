export const searchCollections = {
  documents: [
    { name: '(ODS) Official Document System', collection: 'ods' },
    {
      name: '(OAJ) Office Administrative Justice',
      collection: 'oaj',
    },
    { name: '(REP) Security Council Repertoire', collection: 'rep' },
  ],
  websites: [
    { name: 'UN.org', collection: 'un' },
    {
      name: '(DESA) Department of Economic and Social Affairs',
      collection: 'desa',
    },
  ],
}
