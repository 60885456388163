import React from 'react'
import './SearchSuggestion.css'

export default function SearchSuggestions({
  searchedSuggestions,
  onSelectSearchTerm,
}) {

  const onSelectSearchSuggestion = (searchTerm) => {
    onSelectSearchTerm(searchTerm)
  }


  return (
    <section className="search-suggestion">
      {searchedSuggestions.map(({ id, term }) => (
        <button
          key={id}
          className="search-term-button"
          onClick={() => onSelectSearchSuggestion(term)}
          type='button'
        >
          <span>{term}</span>
        </button>
      ))}
    </section>
  )
}
