// const publicationDate = [
//   {
//     value: '2022',
//     hasNoLocalization: true,
//   },
//   { value: '2021', hasNoLocalization: true },
//   { value: '2020', hasNoLocalization: true },
//   { value: '2019', hasNoLocalization: true },
//   { value: '2018', hasNoLocalization: true },
//   { value: '2017', hasNoLocalization: true },
//   { value: '2016', hasNoLocalization: true },
//   { value: '2015', hasNoLocalization: true },
//   { value: '2014', hasNoLocalization: true },
//   { value: '2013', hasNoLocalization: true },
//   { value: '2012', hasNoLocalization: true },
//   { value: '2011', hasNoLocalization: true },
//   { value: '2010', hasNoLocalization: true },
//   { value: '2009', hasNoLocalization: true },
//   { value: '2008', hasNoLocalization: true },
//   { value: '2007', hasNoLocalization: true },
//   { value: '2006', hasNoLocalization: true },
//   { value: '2005', hasNoLocalization: true },
//   { value: '2004', hasNoLocalization: true },
//   { value: '2003', hasNoLocalization: true },
//   { value: '2002', hasNoLocalization: true },
//   { value: '2001', hasNoLocalization: true },
//   { value: '2000', hasNoLocalization: true },
// ]

const unBodies = [
  {
    title: 'generalAssembly',
    value: 'A/*',
    subGroup: [
      'A/C.1/*',
      'A/C.2/*',
      'A/C.3/*',
      'A/C.4/*',
      'A/C.5/*',
      'A/C.6/*',
    ],
  },
  {
    title: 'securityCouncil',
    value: 'S/*',
    subGroup: null,
  },
  {
    title: 'economicAndSocialCouncil',
    value: 'E/*',
  },
  {
    title: 'economicCommission',
    value: 'economicCommission',
    subGroup: ['E/ECA/*', 'ECE/*', 'LC/*', 'E/ESCAP/*', 'E/ESCWA/*'],
  },
  {
    title: 'humanRightCouncil',
    value: 'A/HRC/*',
    subGroup: null,
  },
  {
    title: 'secretariat',
    value: 'ST/*',
    subGroup: ['ST/AI/*', 'ST/IC/*', 'ST/SGB/*'],
  },
  {
    title: 'administrativeTribunal',
    value: 'AT/*',
    subGroup: null,
  },
  {
    title: 'aplConvention',
    value: 'APLC/*',
    subGroup: null,
  },
]



const languageCode = [
  { value: 'ar' },
  { value: 'zh-cn' },
  { value: 'en' },
  { value: 'fr' },
  { value: 'ru' },
  { value: 'es' },
  { value: 'other' },
]

function filterByYearRange() {
  let arr = []
  const date = new Date();

  for (let i = 1946; i < date.getFullYear() + 1; i++) {
    arr.push(i)
  }
  return arr
}

export function ods() {
  return {
    unBodies,
    // languageCode,
    // publicationDate,
    filterByYearRange: filterByYearRange()
  }
}
