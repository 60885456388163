import React, { useState, useEffect } from 'react';
import './multi-select.css';
import { DynamicSort } from 'utils/numbers';

export const MultiSelect = ({ inputList, setSelectedValue, closeDialog }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        // Break apart the input list and sort properly...
        const newList = [];
        inputList.forEach(elt => {
            newList.push([elt[0].toLowerCase(), elt[1]]);
        });

        setData(newList);

    }, [inputList]);

    return (
        <div className='multi-select-container'>
            <div className='list-view'>
                {data.sort(DynamicSort(0)).map((item, index) => {
                    return <div
                        className='list-item'
                        key={index}
                        onDoubleClick={() => {
                            setSelectedValue(item[0])
                            closeDialog()
                        }}
                    >{item[0]}
                    </div>
                })}
            </div>
            <span className='hint'>(Double-click to choose...)</span>
        </div>
    )
}
