import React from 'react'
import { t } from '../../../i18n/config'

export default function ExportResult({ exportResult, totalDocsFound, isDownloading }) {
  const numberOfDocs = totalDocsFound > 500 ? 500 : totalDocsFound;
  const titleString = t('max_downloadable').replace('$a', numberOfDocs);

  return (
    <div className="filter" id="download-searchresult" style={{
      textAlign: 'center'
    }}>
      <button className="btn- btn-main btn-download"
        title={titleString}
        onClick={exportResult}
        disabled={isDownloading}
      >
        <i className="bx bx-download" /> {isDownloading ? t('downloading') : t('download_search_results')}
      </button>
    </div >
  )
}
