import React, { useState, useEffect, useCallback } from 'react'
import CustomModal from 'components/Modal';
import { getReferences } from 'services/document';
import 'react-tree-graph/dist/style.css'
import './Styles.css'
import ReactJSmind from 'components/jsMind/index-';
import { t } from 'i18n/config';

export default function ReferenceExplorer({ handleClose, visible, languageCode, initialSymbol }) {

  // declare variables
  const [symbol, setSymbol] = useState(initialSymbol)
  const [mind, setMind] = useState({
    meta: {
      name: 'ref_explorer',
      author: 'hizzgdev@163.com',
      version: '0.2',
    },
    format: 'node_array',
  });
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [arrayOfNodes, setArrayOfNodes] = useState([]);

  // start getData()
  const getData = useCallback(async (symbol) => {
    // helper functions
    // start getMatchingYears()
    const getMatchingYears = objectArray => {

      // get only the years array...
      const matchingYearsArray = [];

      for (let i = 0; i < objectArray?.length; i++) {
        const year = objectArray[i].publicationDate?.substring(0, 4);

        // if the year value doesnt exist, then add...
        if (matchingYearsArray.indexOf(year) === -1) {
          matchingYearsArray.push(year);
        }
      }

      return matchingYearsArray.sort();

    }

    // Get the data from the endpoint...
    const { data, status } = await getReferences(symbol, languageCode, setIsFetchingData);

    // create mind
    let rdata = [
      { id: 'root', isroot: true, topic: symbol, 'font-size': '14' },
      { id: 'referencing', parentid: 'root', 'topic': 'Referencing', direction: 'left', 'font-size': '12' },
      { id: 'referencedBy', parentid: 'root', 'topic': 'Referenced By', direction: 'right', 'font-size': '12' },
    ];

    const nodesArray = [];

    // Get the referencing data...
    const referencingYears = getMatchingYears(data.referencing);

    referencingYears?.forEach(refYear => {
      const nodeName = '_referencing_' + refYear;
      // First, filter, then set them to the right year values...
      rdata.push({
        id: nodeName,
        parentid: 'referencing',
        topic: refYear,
        expanded: false,
        direction: 'left',
        'font-size': 11
      })

      // Each other node for each year...
      const refList = data.referencing?.filter(item => item.publicationDate.substring(0, 4) === refYear);
      refList?.forEach((eachRef, index) => {
        const id = eachRef?.name + '_a';
        const control = `<button id="${id}">${eachRef.name}</button>`;

        rdata.push({
          id,
          parentid: "_referencing_" + refYear,
          topic: control,
          direction: 'left',
          'font-size': 10
        })

        const thisNode = { nodeId: id, symbol: eachRef.name }
        nodesArray.push(thisNode);
      })
    })

    // Then get the referencedBy data...
    const referencedByYears = getMatchingYears(data.referencedBy);
    //console.log('fromgendatarefBYyears==>' + referencedByYears)
    referencedByYears?.forEach(refYear => {
      const nodeName = '_referencedBy_' + refYear;
      rdata.push({
        id: nodeName,
        parentid: 'referencedBy',
        topic: refYear,
        expanded: false,
        direction: 'right',
        'font-size': 11
      })

      const refByList = data.referencedBy?.filter(item => item.publicationDate?.substring(0, 4) === refYear);
      refByList?.forEach((eachRef, index) => {
        const id = eachRef?.name + '_b';
        const control = `<button id="${id}">${eachRef.name}</button>`;

        rdata.push({
          id,
          parentid: '_referencedBy_' + refYear,
          topic: control,
          direction: 'right',
          'font-size': 10
        })

        const thisNode = { nodeId: id, symbol: eachRef.name }
        nodesArray.push(thisNode);
      })
    })

    if (status == 200 && (data.referencing.length > 0 || data.referencedBy.length > 0)) {
      // Add the nodes array to the arrayOfNodes..
      setArrayOfNodes(nodesArray);

      setMind({
        meta: {
          name: 'ref_explorer',
          author: 'hizzgdev@163.com',
          version: '0.2',
        },
        format: 'node_array',
        data: rdata
      });
    } else {
      //console.log('else triggered ' + symbol);
      setMind({
        meta: {
          name: 'ref_explorer',
          author: 'hizzgdev@163.com',
          version: '0.2',
        },
        format: 'node_array',
        data: [
          { id: 'root', isroot: true, topic: symbol, 'font-size': '14' },
          { id: 'referencing', parentid: 'root', 'topic': 'Referencing', direction: 'left', 'font-size': '12' },
          { id: 'referencedBy', parentid: 'root', 'topic': 'Referenced By', direction: 'right', 'font-size': '12' },
        ]
      });
      const tempArray = [];
      const tempNode = { nodeId: '1', symbol: '1' };
      tempArray.push(tempNode);

      setArrayOfNodes(tempArray);

    }

  }, [symbol])

  useEffect(() => {

    if (visible) {
      getData(symbol);
    } else {
      setMind({
        meta: {
          name: 'ref_explorer',
          author: 'hizzgdev@163.com',
          version: '0.2',
        },
        format: 'node_array',
        data: [
          { id: 'root', isroot: true, topic: symbol, 'font-size': '14' },
          { id: 'referencing', parentid: 'root', 'topic': 'Referencing', direction: 'left', 'font-size': '12' },
          { id: 'referencedBy', parentid: 'root', 'topic': 'Referenced By', direction: 'right', 'font-size': '12' },
        ]
      });
      arrayOfNodes.current = [];
    }

  }, [visible, symbol])

  useEffect(() => {
    setSymbol(initialSymbol);
  }, [initialSymbol, visible])


  const onClose = () => {
    setSymbol(initialSymbol);
    handleClose();
  }

  // render page
  return (
    <CustomModal
      isFetchingData={isFetchingData}
      handleClose={onClose}
      visible={visible}
      header={t('references_for').replace('$symbol', symbol)}
    >
      <div id="treeWrapper" className='node-tree-view' aria-hidden={true} tabIndex={-1}>
        <ReactJSmind
          mind={mind}
          nodesCollection={arrayOfNodes}
          setSymbol={setSymbol}
          symbol={symbol}
          visible={visible}
        />
      </div>
    </CustomModal >
  )
}
