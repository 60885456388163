import React from 'react'
import PdfViewer from './PdfViewer'
import { ResultItem } from './ResultItem'
const apiUrl = window.location.origin == 'http://localhost:3000' ? 'http://localhost:4000/api' : window.location.origin + process.env.REACT_APP_API_URL

export default function DocListInPdfViewer({
  docs,
  collection,
  searchedTerm,
  showPdfViewer,
  setPdfUrl,
  pdfUrl,
  pdfViewerIsVisible,
}) {

  const setPDFUrl = (url) => {
    if (!pdfViewerIsVisible) showPdfViewer()
    setPdfUrl(url)
  }

  return (
    <section className="pdf-viewer-container">
      <div className="pdf-viewer-docs-list">
        {docs.map((doc) => {
          return (
            <>
              <ResultItem
                key={doc.digest + doc.id}
                doc={doc}
                searchedTerm={searchedTerm}
                collection={collection}
                pdfViewerIsVisible={pdfViewerIsVisible}
                setPDFUrl={() => setPDFUrl(doc.url)}
                url={pdfUrl}
              />
            </>
          )
        })}
      </div>
      <PdfViewer
        fileUrl={`${apiUrl}/proxy?url=${pdfUrl}#search=${searchedTerm}`}
      />
    </section>
  )
}
