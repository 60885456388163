import React, { createRef, useEffect } from 'react'
import PDFJSBackend from 'utils/pdfViewer'

function PdfViewer({ fileUrl }) {
  const viewerRef = createRef()
  const initializedBackend = new PDFJSBackend()

  useEffect(() => {
    initializedBackend.init(fileUrl, viewerRef.current)
  }, [fileUrl])

  return (
    <div
      ref={viewerRef}
      id="viewer"
      style={{ width: '100%', height: '100%' }}
    ></div>
  )
}

export default PdfViewer
