import React, { useEffect } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

export default function ResultPhp() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  //const history = useHistory();
  const tpl = searchParams.get('tpl');
  const query = searchParams.get('query');
  const fq = searchParams.get('fq');
  var collection = null;
  var q = null;

  const params = new URLSearchParams();

  //process and convert params
  if (tpl == 'unn') {
    collection = 'un';
  } else if (tpl == 'ods') {
    collection = 'ods';
  } else if (tpl == 'oaj') {
    collection = 'oaj';
  } else if (tpl == 'rep') {
    collection = 'rep';
  } else if (tpl == 'desa') {
    collection = 'desa';
  } else if (tpl == 'un') {
    collection = 'un';
  } else {
    collection = 'All';
  }

  if ((query) && (query != 'Search')) {
    q = query;
  } else if (query == 'Search') {
    q = '*';
  } else {
    q = '*';
  }

  params.append('collection', collection);
  params.append('q', q);
  //console.log(params.toString());

  useEffect(() => {
    navigate({
      pathname: '/search',
      search: params.toString()
    });
  });

  return null;
}