import { t } from 'i18n/config'
import React from 'react'

export default function OptionButtons({
  collection,
  showViewPlainTextModal,
  showReferenceExplorer,
  url,
  fullTextAvailableLanguages
}) {

  return (
    <span className="option-btns-container float-right">
      {collection === 'ods' && (
        <>
          <button title={t('reference_explorer')} className="option-btn" onClick={showReferenceExplorer}>
            <i className="fa fa-rocket fa-lg option-icon"></i>
          </button>
        </>
      )}
      {/* {collection === 'ods' && (
        <button
          title="Available Document Languages"
          className="option-btn"
          onClick={showAvailableLanguages}
        >
          <i className="fa fa-globe fa-lg option-icon"></i>
        </button>
      )} */}
      {(collection === 'oaj' || collection === 'rep') && <a href={url} target="_blank" className='option-btn' title={t('download_document')}>
        <i className="fa fa-paperclip preview-icon"></i>
      </a>}
      {/* {collection !== 'un' && collection !== 'desa' && (collection === 'ods' && fullTextAvailableLanguages.length > 0) && */}
      {(collection === 'rep' || collection === 'oaj' || (collection === 'ods' && fullTextAvailableLanguages.length > 0)) &&
        <button title={t('view_plain_text')} className="option-btn" onClick={showViewPlainTextModal}>
          <i className="fa fa-file-text-o fa-lg option-icon"></i>
        </button>
      }
    </span>
  )
}
