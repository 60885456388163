import SearchResult from 'pages/SearchResult'
import ResultPhp from 'pages/ResultPhp'
import SignIn from 'pages/SignIn'
import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from '../pages/Home'

export function RenderRoutes() {

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/search" element={<SearchResult />} />
      <Route path="/results.php" element={<ResultPhp />} />
      <Route element={() => <h1>Not Found!</h1>} />
    </Routes>
  )
}

export function RenderRoutesWithNoLayoutes() {
  return <Routes></Routes>
}
