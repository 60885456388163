/* eslint-disable global-require */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const userLanguage = localStorage.getItem('userLanguage')

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: userLanguage ? userLanguage : 'en',
  resources: {
    ar: {
      translations: require('./locales/ar/translations.json'),
      facet: require('./locales/ar/facet.json'),
      fieldQuery: require('./locales/ar/fieldQuery.json'),
    },
    en: {
      translations: require('./locales/en/translations.json'),
      facet: require('./locales/en/facet.json'),
      fieldQuery: require('./locales/en/fieldQuery.json'),
    },
    es: {
      translations: require('./locales/es/translations.json'),
      facet: require('./locales/es/facet.json'),
      fieldQuery: require('./locales/es/fieldQuery.json'),
    },
    fr: {
      translations: require('./locales/fr/translations.json'),
      facet: require('./locales/fr/facet.json'),
      fieldQuery: require('./locales/fr/fieldQuery.json'),
    },
    ru: {
      translations: require('./locales/ru/translations.json'),
      facet: require('./locales/ru/facet.json'),
      fieldQuery: require('./locales/ru/fieldQuery.json'),
    },
    zh: {
      translations: require('./locales/zh-CN/translations.json'),
      facet: require('./locales/zh-CN/facet.json'),
      fieldQuery: require('./locales/zh-CN/fieldQuery.json'),
    },
  },
  ns: ['translations', 'facet', 'fieldQuery'],
  defaultNS: 'translations',
})

i18n.languages = ['ar', 'en', 'es', 'fr', 'ru', 'zh']

// Change language function...
export const getCurrentLanguage = () => {
  return i18n.language;
}

export const setCurrentLanguage = newLanguage => {
  i18n.changeLanguage(newLanguage);
}

export const t = name => {
  return i18n.t(name);
}

export default i18n
