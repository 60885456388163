export function formatNumberWithCommas(x) {
  //return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
  return x.toLocaleString('en-US').toString()
}

export const DynamicSort = (property, sortType = "asc") => {
  const sortOrder = sortType === "asc" ? 1 : -1;

  return function (a, b) {
      const secVal = (a[property] > b[property]) ? 1 : 0;
      const result = (a[property] < b[property])
          ? -1
          : secVal;

      return result * sortOrder;
  }
}
