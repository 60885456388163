import React, { useState, useEffect, useRef } from 'react'
import queryString from 'query-string'
import Dropdown from 'components/Dropdown'
import { useTranslation } from 'react-i18next'

const options = ['relevance', 'ascending', 'descending']


export default function SortDocuments({ onSearch }) {
  const parsedQueries = queryString.parse(location.search)
  const [selectedFacets, setSelectedFacets] = useState(parsedQueries);
  const [sortValue, setSortValue] = useState('relevance')
  const prevSort = useRef('relevance')

  const { t } = useTranslation()

  useEffect(() => {
    // Get the current value for sort...
    setSortValue(parsedQueries.sort || 'relevance');
  }, []);

  useEffect(() => {
    if (prevSort.current == sortValue) {
      // do nothing
    } else {
      onSearch(parsedQueries.q, selectedFacets);
      prevSort.current = sortValue;
    }

  }, [sortValue])

  const onChanglehandler = (val) => {

    // Follow the same manner for the facets selection,
    // instead of refreshing the form...
    // Set this as the sortValue...
    setSortValue(val);
    setSelectedFacets({
      ...parsedQueries,
      sort: val
    })

  }

  return (
    <div className="items-per-page">
      <select
        className='select-form-control'
        onChange={e => onChanglehandler(e.target.value)}
        value={sortValue}
      >
        {options.map((val, index) => {
          if (index === 0) {
            // Relevance...
            return (<option key={val} value={val}>{`${t('sortBy')}: ${t(val)}`}</option>)
          } else {
            // Sort by date ascending / descending
            return (<option key={val} value={val}>{`${t('sortBy')}: ${t('date')} ${t(val)}`}</option>)
          }
        })}
      </select>
    </div >
  )
}
