/* eslint-disable */

export default class PDFJs {
    init = (source, element) => {
        const iframeContainer = document.getElementById('pdf-viewer-iframe')
        const iframe = iframeContainer
            ? iframeContainer
            : document.createElement('iframe')
        iframe.src = `/pdfjs-2.13.216-dist/web/viewer.html?file=${source}`
        iframe.width = '100%'
        iframe.height = '100%'
        iframe.id = 'pdf-viewer-iframe'
        element.appendChild(iframe)
    }
}
