import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Modal.css';
import Loader from 'components/Loader-Old';
import { t } from 'i18n/config';

const CustomModal = (props) => {
  const {
    handleClose,
    visible,
    isFetchingData,
    children,
    header,
    ref,
    plainTextLanguage,
    setPlainTextLanguage,
    fullTextAvailableLanguages,
    dir,
    LowerHeader,
    lowerHeaderPresent

  } = props;

  const langs = ['العربية', '中文', 'English', 'Français', 'Русский', 'Español', 'Deutsch'];
  const langCodes = ['ar', 'zh', 'en', 'fr', 'ru', 'es', 'other'];
  const screenLanguage = localStorage.getItem('userLanguage') || 'en';
  const [screenHeight, setScreenHeight] = useState(500);

  useEffect(() => {
    const docHeight = Math.max(document.querySelector(".modal-content")?.offsetHeight * 0.6, 500);
    setScreenHeight(docHeight);

  }, [isFetchingData]);

  return (
    <Modal show={visible} onHide={handleClose} animation={true} size="xl" ref={ref} style={{ direction: screenLanguage === 'ar' ? 'rtl' : 'ltr' }}>
      {isFetchingData ? <Loader /> :
        <>
          <Modal.Header>
            <Modal.Title closeButton style={{ display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <h4 style={{ fontSize: 17, direction: dir }}>{header}</h4>
              <i className='icofont-close' role='button' onClick={handleClose} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {lowerHeaderPresent && <LowerHeader />}
            <div className='btn-group-manual' style={{ direction: screenLanguage === 'ar' ? 'rtl' : 'ltr' }}>
              {
                fullTextAvailableLanguages?.length > 0 && fullTextAvailableLanguages.map((lang, index) => {
                  return <button
                    className={`
                                btn btn-default border-radiu-left ${((index === 0 && plainTextLanguage !== 'ar') || (index === fullTextAvailableLanguages.length - 1 && plainTextLanguage === 'ar')) && ' first-button'}
                                ${((index === 0 && plainTextLanguage === 'ar') || (index === fullTextAvailableLanguages.length - 1 && plainTextLanguage !== 'ar')) && ' last-button'}
                                ${plainTextLanguage === lang ? ' selected' : ''}`
                    }
                    onClick={() => setPlainTextLanguage(lang)}
                    style={{ wordWrap: 'normal' }}
                    key={index + lang}
                  >
                    {langs[langCodes.indexOf(lang)]}
                  </button>
                })
              }
            </div>
            <div style={header.toLowerCase().indexOf('ref') === -1 ? { display: 'block', position: 'relative', flexWrap: 'wrap', overflowY: 'auto', maxHeight: screenHeight, height: screenHeight, width: '100%' } : { height: 'auto' }}>
              {children}
            </div>
          </Modal.Body>
        </>
      }
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          style={{ backgroundColor: '#009edb', border: 'none', outline: 'none' }}
          data-dismiss="modal"
          onClick={handleClose}
        >
          {t('close')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default CustomModal
