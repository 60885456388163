import React, { useState, useEffect } from 'react';
import './Styles.css'
import { t } from 'i18n/config';

const langCaps = ["A", "C", "E", "F", "R", "S", "O"];
const langs = ['ar', 'zh-cn', 'en', 'fr', 'ru', 'es', 'other'];

export default function AvailableLanguages({ symbol, languages }) {

  return (
    <div>
      <div style={{ textAlign: 'right', padding: 5 }}>
        <div className='btn-group' role="group" aria-label="Language Buttons">
          {languages?.map((language, index) => {
            const langsIndex = langs.indexOf(language);

            return (
              <LanguageAnchor
                key={symbol + index}
                index={index}
                symbol={symbol}
                langsIndex={langsIndex}
                languages={languages}
              />
            )
          })}
        </div>
      </div>
    </div >
  )
}

const LanguageAnchor = ({ index, symbol, langsIndex, languages }) => {
  const displayLanguages = ['العربية', '中文', 'English', 'Français', 'Русский', 'Español', 'Deutsch'];
  const [showCopyFlyout, setShowCopyFlyout] = useState(false);

  const accessDocumentURL = window.location.hostname.indexOf('-dev') !== -1 ? 'https://documents-dev.un.org/api/symbol/access?' : 'https://documents.un.org/api/symbol/access?';

  return (
    <a
      href={`${accessDocumentURL}s=${symbol}&l=${langCaps[langsIndex]}`}
      target="_blank"
      className={`
                    btn btn-default border-radiu-left ${index === 0 && 'first-button'}
                    ${languages.length - 1 === index && 'last-button'}`
      }
      rel="noreferrer"
      onMouseOver={() => setShowCopyFlyout(true)}
      onMouseLeave={() => setShowCopyFlyout(false)}
    >
      {displayLanguages[langsIndex]}
      <CopyFlyout
        show={showCopyFlyout}
        link={`${accessDocumentURL}s=${symbol}&l=${langCaps[langsIndex]}`}
      />
    </a>
  )
}

const CopyFlyout = ({ show, link }) => {
  const [copied, setCopied] = useState(false);
  const [opacity, setOpacity] = useState('');

  useEffect(() => {
    if (copied) {
      setOpacity('non-opaque');
      setTimeout(() => {
        setCopied(false);
        setOpacity('');
      }, 5000);
    }
  }, [copied]);

  return (show && <div className='download-icon-flyout'>
    <div className='icon' role='button' onClick={async (e) => {
      e.preventDefault();
      e.stopPropagation();
      await navigator.clipboard.writeText(link);
      setCopied(true);
    }}>
      <i className='bx bx-copy' role='img' />
      <sub>{t('copy_url')}</sub>
      {copied && <span className={'copied-popup ' + opacity}>{t('link_copied')}</span>}
    </div>
  </div>)
}
