import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Label from './Label';
import { t as tr } from '../../../../i18n/config'

export const FacetAccordion = ({ fieldName, fieldValues, selectedFacets, setSelectedFacets }) => {
  /**
   * This is for the OAJ search and results...
   */
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('facet')

  const ToggleAccordion = () => {
    setIsOpen((prevState) => !prevState)
  }

  useEffect(() => {
    if (selectedFacets[fieldName]) {
      setIsOpen(true)
    }
  }, [])

  const toggleCheckBox = (_fieldName, value) => {
    //get all the query param in the url
    // const parsedQueries = facetList

    //here we getting the current value of query params and checking if the value is not null
    const queryParam = selectedFacets[_fieldName]

    if (queryParam) {
      //spliting the values into an array
      let parsedQueryParam = queryParam.split(',')

      //check if the selected filter query exited in the url already and
      //if the selected filter query existed we remove it from the query param
      if (parsedQueryParam.includes(value)) {
        // removed filter query from an queryParam
        parsedQueryParam = parsedQueryParam.filter((item) => item !== value)
      }
      //if the filter query does not exist we add it here
      else {
        parsedQueryParam = [...parsedQueryParam, value]
      }
      //adding or updating query params to the url
      const params = {
        ...selectedFacets,
        [_fieldName]: parsedQueryParam.toString(),
      }
      setSelectedFacets(params)
    } else {
      const params = {
        ...selectedFacets,
        [_fieldName]: value,
      }
      setSelectedFacets(params)
    }
  }

  const checkFilterQueryIsselected = (filterQuery, value) => {
    const fldValues = selectedFacets[filterQuery];
    return fldValues && fldValues.includes(value) ? true : false;
  }

  return (
    <div className="dropdown-container">
      <Label
        label={fieldName === 'languageCode' ? tr('language') : tr(`${fieldName}`)}  // We're changing this from languageCode to langauge as per project requirement...
        onClickToggleAccordion={() => ToggleAccordion()}
        isOpen={isOpen}
      />
      {isOpen && (
        <div style={{ paddingLeft: 20 }}>
          {fieldValues.map((value, index) => {
            const isSelected = checkFilterQueryIsselected(fieldName, value)
            if (index % 2 === 0)
              return (
                <div className="d-flex flex-column" key={fieldName + value}>
                  <div className="face-dropdown ">
                    <label for={'chk' + value}>
                      <input
                        id={'chk' + value}
                        type="checkbox"
                        key={value}
                        checked={isSelected}
                        name={value}
                        onChange={() => toggleCheckBox(fieldName, value)}
                      />
                      {/* Value and the search results found per item... */}
                      {t(value)} ({fieldValues[index + 1]})
                    </label>
                  </div>
                </div>
              )
          })}
        </div>
      )}

    </div>
  )
}
