import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FacetAccordion } from './FacetAccordion'
import Label from './Label'
import '../SearchResult.css'
import { useSearchParams } from 'react-router-dom';
import queryString from 'query-string'
import './index.css';
import { t as tr, getCurrentLanguage } from 'i18n/config'


const FilterOptionButtons = ({ resetFilters, applyFilter }) => {
  const { t } = useTranslation('fieldQuery');

  return (
    <div className="reset-buttons">
      <button className="btn blue_button"
        aria-label={t('applyFilter')}
        onClick={applyFilter}>
        {t('applyFilter')}
      </button>
      <button className="btn blue_button" aria-label={t('resetFilterQuery')} onClick={resetFilters}>
        {t('resetFilterQuery')}
      </button>
    </div>
  )
}

export default function Facet({ facets, resetFilters, showFacet, onSearch }) {
  const { t } = useTranslation('fieldQuery')

  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [searchParams, setSearchParams] = useSearchParams('');
  const parsedQueries = queryString.parse(location.search)
  delete parsedQueries.languageCode
  const [selectedFacets, setSelectedFacets] = useState(parsedQueries)

  const applyFilter = () => {
    onSearch(parsedQueries.q, selectedFacets)
  }

  useEffect(() => {
    // Validate date fields to ensure that the date range is positive...
    if (dateFrom !== "" && dateTo !== "" && dateFrom.length === 10 && dateTo.length === 10) {
      if (dateFrom > dateTo && parseInt(dateFrom.substring(0, 4)) > 1950 && parseInt(dateTo.substring(0, 4)) > 1950) {
        // Alert the user...
        alert(tr('to_from_date_issue'));
        setDateTo(dateFrom);
        
      } else {
        // good to go!...
        handleDatePickerSelect();
      }
    }

    handleDatePickerSelect();

  }, [dateFrom, dateTo])

  useEffect(() => {
    // Fetch the dates from the URL
    const myURL = new URL(window.location.href);
    const df = myURL.searchParams.get('dateFrom') || '';
    const dt = myURL.searchParams.get('dateTo') || '';

    // Set to the state variables...
    setDateFrom(df);
    setDateTo(dt);

  }, [])

  const handleDatePickerSelect = () => {

    if (dateFrom !== '') {
      // Not empty.. Add...
      parsedQueries.dateFrom = dateFrom
    } else {
      delete parsedQueries.dateFrom
    }

    if (dateTo !== '') {
      // Not empty...
      parsedQueries.dateTo = dateTo;
    } else {
      delete parsedQueries.dateTo;
    }

    // Set the outputs for the queries...
    setSelectedFacets({ ...parsedQueries })
    setSearchParams({ ...parsedQueries })
  }


  return (
    <section className={`facet-container ${getCurrentLanguage()}`}>
      <FilterOptionButtons applyFilter={applyFilter} resetFilters={resetFilters} />
      {facets?.facet_fields &&
        Object.entries(facets.facet_fields).map(
          ([fieldName, fieldValues]) =>
            fieldValues.length > 1 && fieldName !== 'mimeType' && ( // We wish to hide mimeType since it's only one filter...
              <FacetAccordion
                fieldName={fieldName}
                fieldValues={fieldValues}
                key={fieldName}
                showFacet={showFacet}
                selectedFacets={selectedFacets}
                setSelectedFacets={setSelectedFacets}
              />
            ),
        )}
      <div className='dropdown-container'>
        <Label
          label={t('judgmentOrderDate.title')}
          showAsDropDown={false}
        />
      </div>

      <div className='date-selectors'>
        <div className='date-input-group'>
          <label for='dateFrom'>{tr('_from')}</label>
          <input
            id='dateFrom'
            type='date'
            className='input-form-control small-date'
            value={dateFrom}
            onChange={e => setDateFrom(e.target.value)}
          />
        </div>
        <div className='date-input-group'>
          <label for='dateTo'>{tr('_to')}</label>
          <input
            id='dateTo'
            type='date'
            className='input-form-control small-date'
            value={dateTo}
            onChange={e => setDateTo(e.target.value)}
          />
        </div>
      </div>

    </section>
  )
}
