import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './assets/200/icofont/icofont.min.css'
import './assets/200/boxicons/css/boxicons.min.css'
import './assets/200/ionicons/css/ionicons.min.css'
// import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import App from './App'
import './i18n/config'
import * as serviceWorker from './serviceWorker'
import './layouts/nav.css';

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById('root'),
)

serviceWorker.unregister();
