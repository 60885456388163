
//const apiUrl = process.env.REACT_APP_API_URL;
const apiUrl = window.location.origin == 'http://localhost:3000' ? 'http://localhost:4000/api' : window.location.origin + process.env.REACT_APP_API_URL;

const parseAndFetchAPI = async (url, showLoader) => {
  let data, status;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'public, max-age=0',
      'X-CSRF-Token': sessionStorage.getItem('csrfToken') || '-',
      'withCredentials': true
    }
  }

  try {

    showLoader(true);

    // Fetch the API..
    const getData = await fetch(url, options);
    const response = await getData.json();

    showLoader(false);

    data = response;
    status = getData.status;

    sessionStorage.setItem('csrfToken', response?.token);

    return { data, status };

  } catch (error) {
    // Issue occured...
    showLoader(false);
    data = error;
    status = false;

    return { data, status }
  }
}

export const viewPlainText = async (id, collection, showLoader, plainTextLanguage) => {

  // Fetch with new method...
  const URL = `${apiUrl}/document/view_plain_text/?id=${id}&collection=${collection}&lang=${plainTextLanguage}`
  const { data, status } = await parseAndFetchAPI(URL, showLoader);

  return { data, status }
}

export const getAvailableLanguages = async (symbol, collection, showLoader) => {

  const URL = `${apiUrl}/document/available_languages/?symbol=${symbol}&collection=${collection}`;
  const { data, status } = await parseAndFetchAPI(URL, showLoader);

  return { data, status }
}

export const getReferences = async (symbol, languageCode, showLoader) => {

  const URL = `${apiUrl}/document/reference-explorer/?symbol=${symbol}&languageCode=${languageCode}`;
  const { data, status } = await parseAndFetchAPI(URL, showLoader);

  return { data, status }
}
