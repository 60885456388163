import React, { useContext, useRef, useState, useEffect } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import { setCurrentLanguage, getCurrentLanguage, t } from "../../i18n/config";
import { LanguageSelectionContext } from "context/languageLocalization";
import queryString from 'query-string';
import { useSpeechChangedUpdateText } from "context/SpeechTextContext";

export const LanguageBar = () => {
    const [rightHeaderDisplayState, setRightHeaderDisplayState] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(getCurrentLanguage());
    const { handleSelectLanguage } = useContext(LanguageSelectionContext)
    const parsedQueries = queryString.parse(location.search);

    const flyoutMenu = useRef();
    const setSpeechChanged = useSpeechChangedUpdateText();

    useEffect(() => {
        setSpeechChanged(true);

    }, [selectedLanguage])

    useClickOutside(flyoutMenu, () => {
        if (rightHeaderDisplayState) {
            setRightHeaderDisplayState(false);
        }
    })

    const toggleRightHeader = e => {
        e.preventDefault();
        setRightHeaderDisplayState(!rightHeaderDisplayState);
    }

    const changeLanguage = (e, lang) => {
        e.preventDefault()
        setCurrentLanguage(lang);

        // Also, make the item bold...
        setSelectedLanguage(lang);

        // Change the language in the URL...
        parsedQueries.languageCode = lang;

        // change the parsedQueries to string...
        let outputString = '?';
        Object.entries(parsedQueries).forEach(([key, value]) => {
            outputString += '&' + key + '=' + value;
        });

        // Set also in context... triggers a page reload...
        handleSelectLanguage(lang);

        // set within localSession
        localStorage.setItem('userLanguage', lang.substring(0, 2));

    }

    const Languages = [
        {
            "caption": "العربية",
            "name": "Arabic",
            "code": "ar",
            "id": 1
        },
        {
            "caption": "中文",
            "name": "Chinese",
            "code": "zh",
            "id": 2
        },
        {
            "caption": "English",
            "name": "English",
            "code": "en",
            "id": 3
        },
        {
            "caption": "Français",
            "name": "French",
            "code": "fr",
            "id": 4
        },
        {
            "caption": "Русский",
            "name": "Russian",
            "code": "ru",
            "id": 5
        },
        {
            "caption": "Español",
            "name": "Spanish",
            "code": "es",
            "id": 6
        }
    ]

    const itemsArray = Languages.map(lang => {
        return <a
            href="#" key={lang.id}
            onClick={e => {
                changeLanguage(e, lang.code)
                toggleRightHeader(e)
                //makeUniversalSearch(lang.code)   // Function to make search in current selected langauge...
            }}
            className={selectedLanguage === lang.code ? 'active' : ''}
        >
            {lang.caption}
        </a>
    })

    return (
        <div className="lang-outside-container bg-un-grey">
            <div className={`lang-container ${selectedLanguage}`}>
                <a href={`https://www.un.org/${selectedLanguage}/`} target="_blank" className="header-left">
                    <i className="ion-ios-home" />
                    <span>{t('welcome')}</span>
                </a>
                <div className="menu" onClick={toggleRightHeader}>
                    <i className="ion-navicon-round" />
                </div>
                <div ref={flyoutMenu} className={rightHeaderDisplayState ? "header-right show" : "header-right"}>
                    {itemsArray}
                </div>
            </div>
        </div>
    )
}
