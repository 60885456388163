export const LOCALSTORAGE = {}

LOCALSTORAGE.store = (key, value) => {
  localStorage.setItem(key, value)
}

LOCALSTORAGE.getItem = (key) => {
  localStorage.getItem(key)
}

const SESSIONSTORAGE = {}

SESSIONSTORAGE.store = (key, value) => {
  sessionStorage.setItem(key, value)
}

SESSIONSTORAGE.getItem = (key) => {
  sessionStorage.getItem(key)
}
