import OutsideClick from 'hooks/clickOutside'
import React from 'react'
import PropTypes from 'prop-types'
import { t, getCurrentLanguage } from 'i18n/config';

import './Dropdown.css'

function Dropdown({
  isOpen,
  toggleDropdown,
  closeDropdown,
  showSelectedItem,
  selectedItem,
  DropdownContent,
  DropdownToggleButton,
  btnClassName,
  showIcon,
  parent
}) {

  const title = parent === 'SearchBar' ? t('choose_collection') : '';

  return (
    <div className={getCurrentLanguage()} style={{ height: '100%'}}>
      {/* This is the dropdown toggle button */}
      {DropdownToggleButton ? (
        <DropdownToggleButton />
      ) : (
        <button
          className={`dropdown-toggle-btn ${getCurrentLanguage()}`}
          aria-label='Search collections'
          title={title}
          onClick={toggleDropdown}
          type="button"
        >
          {showSelectedItem && selectedItem}
          {showIcon &&
            <i className={`fa fa-chevron-right icon ${isOpen && 'open left'}`} style={{ marginLeft: 5 }}></i>
          }
        </button>
      )}
      {/* this section is for Dropdown content */}
      <OutsideClick onOutsideClick={closeDropdown}>
        <div className={`dropdown-content  ${isOpen ? 'open' : 'close'}`}>
          <DropdownContent />
        </div>
      </OutsideClick>
    </div>
  )
}

Dropdown.prototype = {
  isOpen: PropTypes.string,
  toggleDropdown: PropTypes.func,
  closeDropdown: PropTypes.func,
  showSelectedItem: PropTypes.bool,
  selectedItem: PropTypes.string,
  DropdownContent: PropTypes.any,
  DropdownToggleButton: PropTypes.any,
}

export default Dropdown
