const refineBy = [
  {
    value: '/publications',
  },
  {
    value: '/statements',
  },
  {
    value: '/news',
  },
]

const languageCode = [
  { value: 'ar' },
  { value: 'zh-cn' },
  { value: 'en' },
  { value: 'fr' },
  { value: 'ru' },
  { value: 'es' },
  { value: 'other' },
]




export function desa() {
  return {
    refineBy,
    languageCode,
  }
}
