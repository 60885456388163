import React, { useState, useEffect } from 'react';
import './pagination.css';
import { getCurrentLanguage } from 'i18n/config';

const DOTS = () => {
  return (<div className="dots">...</div>)
}

const range = (start, end) => {
  let length = end - start + 1;
  /*
      Create an array of certain length and set the elements within it from
    start value to end value.
  */
  return Array.from({ length }, (_, idx) => idx + start);
};

const Pagination = props => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage = 1, pageSize } = props;

  const [navigationButtons, setNavigationButtons] = useState();
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(0);

  const currentLanguage = getCurrentLanguage();

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const onNavigate = pageNo => {
    onPageChange(pageNo);
  }

  const getCollection = () => {

    const totalPageCount = Math.ceil(totalCount / pageSize);
    //console.log('totalCount=> ' + totalCount);
    //console.log('pageSize=> ' + pageSize);
    //console.log('totalPageCount=> ' + totalPageCount);
    setTotalNumberOfPages(totalPageCount);

    // Pages count is determined as sibblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /**
     * Case 1:
     * If the number of pages is less than the page numbers we want to show in our
     * pagination component, we return the range [1..totalPageCount]
     */

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    /**
     * Calculate left and right sibling index and make sure they are witin range 1 and two
     */
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    /**
     * We do not show dots just when there is just one page number to be inserted between...
     */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    /**
     * Case 2: No left dots to show, but right dots to bev shown..
     */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    /**
     * Case 3: No right dots to show, but left dots to be shown...
     */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    /**
     * Case 4: Both left and right dots to be shown..
     */
    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }

  }

  useEffect(() => {

    //console.log('useeffect pageSize=> ' + pageSize);
    const collection = getCollection();

    let output;

    // Normal navigation buttons till maxCount...
    output = collection.map((item, index) => {
      if (item === DOTS) {
        return <DOTS key={index} />
      }

      // By default, render our nav array...
      return (<button
        key={index}
        className={currentPage === item ? 'active' : ''}
        onClick={() => onNavigate(item)}
      >
        {item}
      </button>)
    })

    // set the buttons collection to become the navigation buttons...
    setNavigationButtons(output);

  }, [currentPage, totalCount, pageSize])

  const lastPage = totalNumberOfPages;

  // The arrow buttons, considering localization settings for R-L languages, say Arabic...
  const arrowHome = currentLanguage === 'ar' ? 'icofont-rounded-double-right' : 'icofont-rounded-double-left';
  const arrowPrevious = currentLanguage === 'ar' ? 'icofont-rounded-right' : 'icofont-rounded-left';
  const arrowNext = currentLanguage === 'ar' ? 'icofont-rounded-left' : 'icofont-rounded-right';
  const arrowEnd = currentLanguage === 'ar' ? 'icofont-rounded-double-left' : 'icofont-rounded-double-right';

  return (
    <div className='pagination-container'>
      <div className="pagination">
        {/* Home navigation */}
        {currentPage === 1
          ? <div><i className={arrowHome} /></div>
          : <button onClick={() => onNavigate(1)}>
            <i className={arrowHome} />
            <span style={{ visibility: 'hidden' }}>.</span>
          </button>
        }
        {/* Left / previous navigation arrow */}
        {currentPage === 1
          ? <div><i className={arrowPrevious} style={{ fontSize: '0.9em' }} /></div>
          // es-lint-disable-next-line
          : <button onClick={onPrevious}>
            <i className={arrowPrevious} style={{ fontSize: '0.9em' }} />
            <span style={{ visibility: 'hidden' }}>.</span>
          </button>
        }
        {/* Acutal navigation buttons... */}
        { navigationButtons }
        {/*  Right Navigation arrow */}
        {
          currentPage === lastPage
            ? <div><i className={arrowNext} style={{ fontSize: '0.9em' }}></i></div>
            : <button onClick={onNext}>
              <i className={arrowNext} style={{ fontSize: '0.9em' }}></i>
              <span style={{ visibility: 'hidden' }}>.</span>
            </button>
        }
        {/* End button */}
        {
          currentPage === lastPage
            ? <div>
              <i className={arrowEnd} />
            </div>
            : <button onClick={() => onNavigate(lastPage)}>
              <i className={arrowEnd} />
              <span style={{ visibility: 'hidden' }}>.</span>
            </button>
        }
      </div>
    </div>
  );
};

export default Pagination;