import { fileTypesIcons } from 'constants/fileTypeIcon'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './SearchResult.css'
import ViewPlainText from '../Document/ViewPlainText'
import AvailableLanguages from '../Document/AvailableLanguages'
import ReferenceExplorer from '../Document/ReferenceExplorer'
import OptionButtons from './OptionButtons'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from 'i18n/config'

const initialModalState = {
  viewPlainText: false,
  availableLanguages: false,
  referenceExplorer: false
}

export function ResultItem({ doc, collection, pdfViewerIsVisible, setPDFUrl, url }) {
  const [modal, setShowModal] = useState(initialModalState)
  const { t } = useTranslation('');
  const langCaps = ["A", "C", "E", "F", "R", "S", "O"];
  const langs = ['ar', 'zh', 'en', 'fr', 'ru', 'es', 'ot'];

  const [plainTextLanguage, setPlainTextLanguage] = useState(() => {
    // Set as either the screen language, or the first language in the queue...
    const scrLang = localStorage.getItem('userLanguage') || 'en';
    if (collection?.toLowerCase() === 'ods') {
      if (doc.fullTextAvailableLanguages.indexOf(scrLang) !== -1) {
        // Is within list...
        return scrLang;
      } else {
        // Loop through and find the first pre-ordered item and set ...
        for (const lang of ['en', 'fr', 'ru', 'es', 'ar', 'zh', 'other']) {
          if (doc.fullTextAvailableLanguages?.indexOf(lang) !== -1) {
            return lang;
          }
        }
      }
    }

    return 'en';

  });

  const toggleModal = (key, value) => {
    setShowModal({ ...initialModalState, [key]: value })
  }

  const title = typeof doc.title === 'object' ? doc.title.join('...') : doc.title;
  const body = typeof doc.body === 'object' ? doc.body.join('(...)') : doc.body

  const shortenURL = uri => {
    // shorten the URL to something simpler...
    const URI = decodeURIComponent(uri);

    if (URI.length <= 40) {
      // Just print out like that...
      return URI;

    } else {
      // If greater than 100 characters... shorten...
      // Split by /'s ...
      const splitURL = URI.split('/');
      const hostName = splitURL[0] + splitURL[1] + '//' + splitURL[2] + '/' + splitURL[3];

      // Get the least element (the actual path)...
      const pathName = splitURL[splitURL.length - 1];
      let shortenedPath = pathName;

      if (pathName.length > 30) {
        shortenedPath = pathName.substring(pathName.length, pathName.length - 30);
      }

      return hostName + ' ... ' + shortenedPath;
    }
  }

  const copyTextToClipboard = async (k, val) => {
    // Copy the text to the clipboard... in this case, the URL...
    if (k === 'url') {
      await navigator.clipboard.writeText(val);
      document.querySelector('.copy-toast').classList.add('animate');

      // remove the animate class immediately...
      setTimeout(() => {
        document.querySelector('.copy-toast').classList.remove('animate');
      }, 3500);
    }
  }

  const innerHighlightedText = hTextArray => {

    if (collection?.toLowerCase() === 'ods') {
      let returnElement = '';

      for (const elt of hTextArray) {
        const langItem = elt.split('_body_')[1];
        const storedUserLang = localStorage.getItem('userLanguage') || 'en';

        if (langItem === storedUserLang?.substring(0, 2)) {
          // This is the one to return...
          returnElement = elt.split('_body_')[0];
          break;
        }
      }

      if (returnElement.length === 0) {
        // Nothing found for the specified language... just show the first element...
        return hTextArray[0].length >= 1000 ? (hTextArray[0].substring(0, 1000) + ' ...') : hTextArray[0].split('_body_')[0];
      } else {
        return returnElement.length >= 1000 ? (returnElement.substring(0, 1000) + ' ...') : returnElement;
      }
    } else {
      const outputText = hTextArray[0].length >= 1000 ? (hTextArray[0].substring(0, 1000) + ' ...') : hTextArray[0];
      return outputText;
    }
  }

  const [screenLanguage] = useState(() => {

    const currentLang = localStorage.getItem('userLanguage') || 'en';

    if (collection?.toLowerCase() === 'ods') {
      let docLanguageCodes = JSON.parse(JSON.stringify(doc.languageCode));

      // Ensure the chinese is replaced as zh, not zh-cn...
      const zhIndex = docLanguageCodes.indexOf('zh-cn');
      docLanguageCodes[zhIndex] = 'zh'

      // Loop and check for the proper language to use...
      let langPos = docLanguageCodes.indexOf(currentLang) !== -1 ? currentLang : '';

      if (langPos === '') {
        for (const lang of ['en', 'fr', 'ru', 'es', 'ar', 'zh', 'other']) {
          if (docLanguageCodes.indexOf(lang) !== -1) {
            langPos = lang;
            setPlainTextLanguage(lang);
            break;
          }
        }
      }
      return langCaps[langs.indexOf(langPos)];

    } else {
      return langCaps[langs.indexOf(currentLang)];
    }
  });

  const accessDocumentURL = window.location.hostname.indexOf('-dev') !== -1
    ? 'https://documents-dev.un.org/api/symbol/access?'
    : 'https://documents.un.org/api/symbol/access?';

  const aHref = doc.url
    ? doc.url
    : collection?.toLowerCase() === 'ods'
      ? accessDocumentURL + 's=' + doc.symbol + '&l=' + screenLanguage
      : doc.id;

  return (
    <>
      <div className='copy-toast'>
        <i className={'bx bx-check-circle ' + getCurrentLanguage()} />
        <span>{t('copy_successful')}</span>
      </div>
      <article
        className={`result-item-container ${(url === doc.url && pdfViewerIsVisible) ? 'active-file' : ''}`}
      >
        <ViewPlainText
          visible={modal.viewPlainText}
          handleClose={() => toggleModal('viewPlainText', false)}
          id={doc.id}
          collection={collection}
          plainTextLanguage={plainTextLanguage}
          setPlainTextLanguage={setPlainTextLanguage}
          fullTextAvailableLanguages={doc.fullTextAvailableLanguages}
        />
        <ReferenceExplorer
          visible={modal.referenceExplorer}
          handleClose={() => toggleModal('referenceExplorer', false)}
          languageCode={doc.languageCode}
          initialSymbol={doc.symbol}
        />
        <div className="result-item-header">
          <div className="d-flex" style={{ alignItems: 'flex-start' }}>
            <div>
              {['ODS', 'REP', 'OAJ'].indexOf(doc['Data Source']) !== -1 ? (
                <i title={t('open_document')}
                  className={`fa fa-cloud ${fileTypesIcons.document}`}
                >&nbsp;</i>
              ) : (
                <i
                  title={t('web_page')}
                  className={`fa fa-cloud ${fileTypesIcons.web}`}
                >
                  &nbsp;
                </i>
              )}
            </div>
            <>
              {collection === 'oaj' || collection === 'rep' ? (
                <a
                  href=''
                  title={t('open_document')}
                  className="file-link"
                  onClick={e => {
                    e.preventDefault();
                    setPDFUrl()
                  }}
                  dangerouslySetInnerHTML={{
                    __html: doc.symbol ? doc.symbol : title
                  }} />
              ) : (
                <a
                  href={aHref}
                  className="file-link"
                  target="_blank"
                  rel="noreferrer"
                  dangerouslySetInnerHTML={{
                    __html: doc.symbol ? doc.symbol : title
                  }} />
              )}
            </>
          </div>
          {collection !== 'All' &&
            <OptionButtons
              collection={collection}
              showViewPlainTextModal={() => {
                toggleModal('viewPlainText', true);
                // Also show the current language...

              }}
              fullTextAvailableLanguages={doc?.fullTextAvailableLanguages || []}
              showAvailableLanguages={() => toggleModal('availableLanguages', true)}
              showReferenceExplorer={() => toggleModal('referenceExplorer', true)}
              url={Array.isArray(doc.url) ? doc.url[0] : doc.url}
            />}
        </div>
        <div className="result-description">
          {doc.symbol && (
            <p className="result-description-text bold" style={{ marginTop: '5px', textAlign: 'justify' }}>
              {doc.title}
            </p>
          )}
          {!doc.highlightedText && (
            <p className="result-description-text" style={{ color: '#333333' }}>{body}</p>
          )}
          {Object.entries(doc).map(
            ([key, value]) => value &&
              key !== 'title' &&
              key !== 'symbol' &&
              key !== 'body' &&
              key !== 'pdfContentType' &&
              key !== 'id' &&
              key !== 'languageCode' &&
              key !== 'fullTextAvailableLanguages' &&
              (key === 'highlightedText'
                ? (<p
                  className="result-description-text"
                  dangerouslySetInnerHTML={{ __html: innerHighlightedText(doc.highlightedText) }}
                  key={key}
                />)
                : (
                  // eslint-disable-next-line
                  (collection?.toLowerCase() === 'ods' && key === 'language')
                    ? <></>
                    : <div
                      key={key}
                      className={key !== 'url' ? 'text-regularize' : 'regular-url'}
                      onClick={() => copyTextToClipboard(key, value)}
                      title={key === 'url' ? t('copy_url') : ''}
                    >
                      <div className='url-container' key={key}>
                        <span style={{ marginRight: '3px' }}>{t(key)}:</span>
                        {/* {checkKey(key)} */}
                        {key === 'language' || key === 'supplement'
                          ? <span style={{ letterSpacing: '.1px' }}>{t(value?.toLowerCase())}</span>
                          : (key === 'url')
                            ? <b style={{ letterSpacing: '.5px', textTransform: 'none', fontWeight: 'normal' }}>{shortenURL(value)}</b>
                            : <span style={{ letterSpacing: '.1px' }}>{value}</span>
                        }
                      </div>

                      {key === 'url' && <div><i className='bx bx-copy-alt hover-copy-icon' style={{ margin: '0 10px' }} /></div>}
                    </div>
                )
              )
          )
          }
        </div>
        {doc.languageCode?.length > 0 && <AvailableLanguages
          symbol={doc.symbol}
          collection={collection}
          visible={true}
          languages={doc.languageCode}
        />}
      </article>
    </>
  )
}

ResultItem.prototype = {
  fileType: PropTypes.string,
  fileUrl: PropTypes.string,
  score: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  language: PropTypes.string,
  dataSource: PropTypes.string,
}
