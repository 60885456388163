import React from 'react';

export default function Label({
  label,
  onClickToggleAccordion,
  isOpen,
  showAsDropDown = true }) {
  return (
    <h1 className="facet-label" onClick={onClickToggleAccordion}>
      {label}
      {showAsDropDown &&
        <button title="Toggle dropdown">
          <i
            className={
              !isOpen
                ? 'icofont-rounded-right' //// 'glyphicon glyphicon-chevron-right'
                : 'icofont-rounded-down'  //// 'glyphicon glyphicon-menu-down'
            }
            data-toggle="collapse"
            data-target="#facetsupplement"
            aria-expanded="true"
          ></i>
        </button>
      }
    </h1>
  )
}