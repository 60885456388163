import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const options = ['10', '25', '50', '100']

export default function ItemsPerPage({ onChangeItemsToShow, itemsPerPage }) {
  const { t } = useTranslation()
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(itemsPerPage || 10);

  const onSelectItem = (val) => {
    setSelectedItemsPerPage(val);

    // Effect other changes...
    onChangeItemsToShow(val)
  }

  return (
    <div className="items-per-page">
      <select
        className='select-form-control'
        onChange={e => onSelectItem(e.target.value)}
        value={selectedItemsPerPage}
      >
        {options.map(val => (<option
          key={val}
          value={val}>{`${t('itemsPerPage')}: ${val}`}</option>)
        )}
      </select>
    </div>
  )
}
