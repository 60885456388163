import React, { Fragment } from 'react'
import { searchCollections } from 'constants/collections'
import './SearchCollection.css'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

function SearchCollection({
  selectedCollection,
  handleSelectCollection,
  closeDropdown,
}) {
  const onSelectCollection = (collection) => {
    handleSelectCollection(collection)
    closeDropdown()
  }

  const [searchParams,] = useSearchParams()

  const { t } = useTranslation('')

  return (
    <div className="collection-container">
      <button
        className="dropdown-select-button"
        onClick={() => onSelectCollection('All')}
        type="button"
        title={t('search_in_all')}
      >
        <span
          className={`select-item-dot ${selectedCollection === 'All' && 'selected'
          }`}
        ></span>
        {t('all')}
      </button>

      {Object.entries(searchCollections).map(([title, collections]) => (
        <Fragment key={title}>
          <p className="dropdown-header">{t(title)}</p>
          <div className="collection-list">
            {collections.map(({ collection }) => (
              <button
                key={collection}
                className={`dropdown-select-button ${searchParams.get('collection') === collection ? 'active' : ''}`}
                onClick={() => onSelectCollection(collection)}
                type="button"
                id={collection}
                title={`${t('search_in')} ${t(collection)} ${t('collection')}`}
              >
                {t(collection)}
              </button>
            ))}
          </div>
        </Fragment>
      ))}
    </div>
  )
}

export default SearchCollection
