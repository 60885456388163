const { ods, oaj, un, rep, desa } = require('constants/filterQuery')

export function formatFilterQueryData(collection) {
  switch (collection) {
  case 'ods':
    return ods()
  case 'oaj':
    return oaj()
  case 'rep':
    return rep()
  case 'un':
    return un()
  case 'desa':
    return desa()
  default:
    return {}
  }
}
