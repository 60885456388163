const type = [
  {
    value: 'disputeTribunal',
  },
  {
    value: 'appealsTribunal',
  },
]

const subType = [
  {
    value: 'judgment',
  },
  {
    value: 'order',
  },
]

const judge = [
  {
    value: 'Judge Izuako',
    hasNoLocalization: true,
  },
  {
    value: 'Judge Ebrahim-carstens ',
    hasNoLocalization: true,
  },
]

const registry = [
  {
    value: ' UNDT - NAIROBI(565)',
    hasNoLocalization: true,
  },
  {
    value: 'UNDT - NEW YORK(375)',
    hasNoLocalization: true,
  },
  {
    value: 'UNAT - NEW YORK(337)',
    hasNoLocalization: true,
  },
  {
    value: 'UNDT - GENEVA(262)',
    hasNoLocalization: true,
  },
]

const fileType = [
  {
    value: 'pdf',
  },
]

const languageCode = [{ value: 'en' }, { value: 'fr' }]

export function oaj() {
  return {
    type,
    subType,
    judge,
    registry,
    fileType,
    // judgmentOrderDate,
    languageCode,
  }
}
