/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import queryString from 'query-string'
import Label from './Label'
import { t as tr } from 'i18n/config'
import { LanguageSelectionContext } from 'context/languageLocalization'
import './index.css';

export const FilterQueryAccordion = ({
  filterQueryName,
  filterQueryValues,
  selectedFilterQueries,
  setSelectedFilterQueries,

}) => {
  /**
   * This is for the ODS...
   */
  const [isOpen, setIsOpen] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const { selectedLanguage } = useContext(LanguageSelectionContext)

  const { t } = useTranslation('fieldQuery')

  const ToggleAccordion = () => {
    setIsOpen((prevState) => !prevState)
  }

  const checkFilterQueryIsselected = (filterQuery, value, subGroup) => {
    const fqVals = selectedFilterQueries[filterQuery]?.split(',')
    let found
    if (subGroup) {
      found = fqVals?.some((r) => subGroup.includes(r))
    }

    return (fqVals && (fqVals.includes(value) || found)) ? true : false;
  }

  const checksubGroupSelected = (filterQuery, subGroup, value) => {
    const fqValues = selectedFilterQueries[filterQuery]
    return (
      fqValues &&
      (fqValues.includes(subGroup) ||
        fqValues.includes(value))
    ) ? true
      : false
  }

  const toggleCheckBox = (_fieldName, value, subGroup) => {

    // selectedFilterQueries.start = 0;
    // selectedFilterQueries.tp = 0;

    //getting the current value of query params and checking if the value is not null
    const queryParam = selectedFilterQueries[_fieldName]

    if (subGroup) {
      value = subGroup.join(',')
    }

    if (queryParam) {
      //spliting the values into an array
      let parsedQueryParam = queryParam.split(',')
      //check if the selected filter query exited in the url already and
      //if the selected filter query existed we remove it from the query param

      if (parsedQueryParam.includes(value)) {
        // removed filter query from an queryParam
        parsedQueryParam = parsedQueryParam.filter((item) => item !== value)
      } else if (
        subGroup &&
        parsedQueryParam.some((r) => subGroup.includes(r))
      ) {
        // removed filter query from an queryParam
        parsedQueryParam = parsedQueryParam.filter(function (el) {
          return !subGroup.includes(el)
        })
      }

      //if the filter query does not exist we add it here
      else {
        parsedQueryParam = [...parsedQueryParam, value]
      }
      //adding or updating query params to the url
      const params = {
        ...selectedFilterQueries,
        [_fieldName]: parsedQueryParam.toString(),
      }
      setSelectedFilterQueries(params)
    } else {

      const params = {
        ...selectedFilterQueries,
        [_fieldName]: value,
      }
      setSelectedFilterQueries(params)
    }
  }

  useEffect(() => {
    filterQueryValues.map((filter) => {
      if (selectedFilterQueries[filter.title]) {
        setIsOpen(true)
      }
    })

    // Ensure that the filter is selected/set to 'Open' after a page refresh or results search...
    if (selectedFilterQueries[filterQueryName]) {
      setIsOpen(true)
    }
    //console.log(' useEffect process queries - ' + JSON.stringify(selectedFilterQueries));
  }, [])


  const toggleInnerCheckBox = (_fieldName, value, subGroup, val2) => {
    //get all the query param in the url
    // filterQueries

    //getting the current value of query params and checking if the value is not null
    const queryParam = selectedFilterQueries[_fieldName]

    if (queryParam) {
      //spliting the values into an array
      let parsedQueryParam = queryParam.split(',')

      if (parsedQueryParam.includes(value)) {
        parsedQueryParam = parsedQueryParam.filter((item) => item !== value)

        const params = {
          ...selectedFilterQueries,
          [_fieldName]: parsedQueryParam.toString(),
        }
        setSelectedFilterQueries(params)

      } else {

        parsedQueryParam = [...parsedQueryParam, value]
        if (checkFilterQueryIsselected(_fieldName, val2)) {
          parsedQueryParam = subGroup.filter((i) => i !== value)
        }

        const params = {
          ...selectedFilterQueries,
          [_fieldName]: parsedQueryParam.toString(),
        }

        setSelectedFilterQueries(params)
      }

    } else {
      const params = {
        ...selectedFilterQueries,
        [_fieldName]: value,
      }
      setSelectedFilterQueries(params)

    }
  }

  useEffect(() => {
    if (selectedFilterQueries.collection === 'rep' && !selectedFilterQueries.languageCode) {
      selectedFilterQueries.languageCode = selectedLanguage
      setSelectedFilterQueries(selectedFilterQueries)
    }
  }, [selectedLanguage])

  const [fromYear, setFromYear] = useState(searchParams.get('fromYear') || '');
  const [toYear, setToYear] = useState(searchParams.get('toYear') || '');

  const handleQueriesParsing = () => {

    // Always set the expected...
    const parsedQueries = queryString.parse(location.search);

    // For the fromYear...
    if (fromYear !== '') {
      // Set it...
      parsedQueries.fromYear = fromYear;
    } else {
      // Remove it from the list...
      delete parsedQueries.fromYear;
    }

    // For the toYear...
    if (toYear !== '') {
      // Set it...
      parsedQueries.toYear = toYear;
    } else {
      // Remove it from the list...
      delete parsedQueries.toYear;
    }

    //parsedQueries.sort = currSort;
    // Set for output...
    // console.log(' process queries - ' + JSON.stringify(parsedQueries))
    setSelectedFilterQueries({ ...parsedQueries })
    setSearchParams({ ...parsedQueries })
    //console.log(' process queries - ' + JSON.stringify(selectedFilterQueries))
  }

  useEffect(() => {
    // Check to ensure that the to year is always greater than then from year...
    if (fromYear && toYear) {
      // With both fields not empty...
      if (parseInt(fromYear) > parseInt(toYear)) {
        alert("'From' year cannot be greater than 'To' year");
        setFromYear(toYear);
      } else {
        // handle the query and others parsing...
        handleQueriesParsing();
      }
    }

    handleQueriesParsing();

  }, [toYear, fromYear])

  return (
    <div className="dropdown-container">
      <Label
        label={t(`${filterQueryName}.title`)}
        onClickToggleAccordion={filterQueryName !== 'filterByYearRange' ? (() => ToggleAccordion()) : (() => null)}
        isOpen={isOpen}
        showAsDropDown={filterQueryName === 'filterByYearRange' ? false : true}
      />
      {
        filterQueryName === 'filterByYearRange' &&
        <div className='d-block face-dropdown'>
          <div className="form-input-group">
            <label htmlFor='from-year-range'>{tr('_from')}</label>
            <select
              className="from-year-range"
              onChange={e => setFromYear(e.target.value)}
              value={fromYear || ''}
              id='from-year-range'
            >
              <option value={''}></option>
              {filterQueryValues.map((val) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </select>
          </div>
          <div className='form-input-group'>
            <label htmlFor='to-year-range'>{tr('_to')}</label>
            <select
              className="from-year-range"
              onChange={e => setToYear(e.target.value)}
              value={searchParams.get('toYear') || ''}
              id='to-year-range'
            >
              <option value=''></option>
              {filterQueryValues.map(mappedValue => (
                <option key={mappedValue} value={mappedValue}>
                  {mappedValue}
                </option>
              ))}
            </select>
          </div>
        </div>
      }
      {isOpen && (
        <div style={{ paddingLeft: 20 }}>
          {filterQueryValues.map(
            ({ title, value, subGroup, hasNoLocalization }) => {
              let _title = title
              if (!value) value = title
              if (!title) _title = filterQueryName
              const isSelected = checkFilterQueryIsselected(_title, value, subGroup)

              return (
                <div
                  className="d-flex flex-column"
                  key={filterQueryName.title + value}
                >
                  <div className="face-dropdown ">
                    <input
                      id={'chk' + value}
                      type="checkbox"
                      key={title}
                      checked={isSelected}
                      name={title}
                      onChange={() => toggleCheckBox(_title, value, subGroup)}
                    />
                    <label for={'chk' + value}>
                      {hasNoLocalization
                        ? value
                        : t(`${filterQueryName}.${value}`)}
                    </label>
                  </div>
                  {/* Filter query sub group */}
                  {subGroup &&
                    subGroup?.map((item, index) => {
                      const subGroupIsSelected = checksubGroupSelected(
                        title,
                        item,
                        value,
                      )
                      return (
                        <div
                          key={item}
                          className="face-dropdown "
                          style={{ paddingLeft: 20 }}
                        >
                          <>
                            <input
                              id={'chk_' + item.toString().substring(0,10) + index }
                              type="checkbox"
                              key={item}
                              name={item}
                              checked={subGroupIsSelected}
                              onChange={() =>
                                toggleInnerCheckBox(
                                  title,
                                  item,
                                  subGroup,
                                  value,
                                )
                              }
                            />
                            <label for={'chk_' + item.toString().substring(0,10) + index }>{t(`${filterQueryName}.${item}`)}</label>
                          </>
                        </div>
                      )
                    })}
                </div>
              )
            },
          )}
        </div>
      )}
    </div>
  )
}
