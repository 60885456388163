import { LanguageSelectionContext } from 'context/languageLocalization'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

export default function NoSearchResult({ searchKeyword }) {
  const { t } = useTranslation('')
  const arr = [1, 2, 3]
  const { selectedLanguage } = useContext(LanguageSelectionContext)
  return (
    <section className="no-result">
      <p>
        {t('yourSearch')} - <strong>{searchKeyword}</strong> - {t('didNotMatch')} {' '}
        <strong>{searchKeyword}</strong>. {selectedLanguage === 'zh-cn' && '- 的网页。'}
      </p>

      <div className='no-results-image-container'>
        <img src='./no-results-found.png' alt='No Results Found!' />
      </div>

      <p>{t('suggestions')}:</p>
      <ul>
        {arr.map((val) => (
          <li key={val}>{t(`didNotMatchSuggestion.${val}`)}</li>
        ))}
      </ul>
    </section>
  )
}
