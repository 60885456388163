
const supplement = [
  {
    value: 'Repertoire (1946-51)',
  },
  {
    value: '1st Supplement (1952-55)',
  },
  {
    value: '2nd Supplement (1956-58)',
  },
  {
    value: '3rd Supplement (1959-63)',
  },
  {
    value: '4th Supplement (1964-65)',
  },
  {
    value: '5th Supplement (1966-68)',
  },
  {
    value: '6th Supplement (1969-71)',
  },
  {
    value: '7th Supplement (1972-74)',
  },
  {
    value: '8th Supplement (1975-80)',
  },
  {
    value: '9th Supplement (1981-84)',
  },
  {
    value: '10th Supplement (1985-88)',
  },
  {
    value: '11th Supplement (1989-92)',
  },
  {
    value: '12th Supplement (1993-95)',
  },
  {
    value: '13th Supplement (1996-99)',
  },
  {
    value: '14th Supplement (2000-03)',
  },
  {
    value: '15th Supplement (2004-07)',
  },
  {
    value: '16th Supplement (2008-09)',
  },
  {
    value: '17th Supplement (2010-11)',
  },
  {
    value: '18th Supplement (2012-13)',
  },
  {
    value: '19th Supplement (2014-15)',
  },
  {
    value: '20th Supplement (2016-17)',
  },
  {
    value: '21st Supplement (2018)',
  },
  {
    value: '22nd Supplement (2019)',
  },
  {
    value: '23rd Supplement (2020)',
  },
  {
    value: '24th Supplement (2021)',
  },
  {
    value: '25th Supplement (2022)',
  },
]

const content = [
  {
    value: 'Table of Contents and Introduction',
  },
  {
    value: 'Index',
  },
]

const chapter = [
  {
    value: 'Chapter 1 (Rules of procedure)',
  },
  {
    value: 'Chapter 2 (Agenda)',
  },
  {
    value: 'Chapter 3 (Participation)',
  },
  {
    value: 'Chapter 4 (Voting)',
  },
  {
    value: 'Chapter 5 (Subsidiary Organs)',
  },
  {
    value: 'Chapter 6 (Membership)',
  },
  {
    value: 'Chapter 7 (Relations with other organs)',
  },
  {
    value: 'Chapter 8 (Overview by agenda item)',
  },
  {
    value: 'Chapter 9 (Functions and Powers)',
  },
  {
    value: 'Chapter 10 (Chapter VI of the UN Charter)',
  },
  {
    value: 'Chapter 11 (Chapter VII of the UN Charter)',
  },
  {
    value: 'Chapter 12 (Purposes and Principles)',
  },
]

const part = [
  {
    value: 'Part I (Overview by agenda item)',
  },
  {
    value: 'Part II (Rules of procedure)',
  },
  {
    value: 'Part III (Purposes and Principles)',
  },
  {
    value: 'Part IV (Relations with other organs)',
  },
  {
    value: 'Part V (Functions and Powers)',
  },
  {
    value: 'Part VI (Chapter VI of the UN Charter)',
  },
  {
    value: 'Part VII (Chapter VII of the UN Charter)',
  },
  {
    value: 'Part VIII (Regional Arrangements)',
  },
  {
    value: 'Part IX (Subsidiary Organs)',
  },
  {
    value: 'Part X (Peace Operations)',
  },
]

const languageCode = [
  { value: 'ar' },
  { value: 'zh-cn' },
  { value: 'en' },
  { value: 'fr' },
  { value: 'ru' },
  { value: 'es' }
]


function filterByYearRange() {
  let arr = []
  for (let i = 1946; i < 2023; i++) {
    arr.push(i)
  }
  return arr
}

export function rep() {
  return {
    supplement,
    content,
    chapter,
    part,
    languageCode,
    filterByYearRange: filterByYearRange()
  }
}
