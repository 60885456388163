import React, { useState } from 'react'
import { LanguageBar } from './LanguageBar'
import { HeaderBar } from './HeaderBar'
import { FooterBar } from './Footer-new'
import { Accessibility } from '../components/Accessibility'

export default function Layout({ children, makeUniversalSearch }) {

  return (
    <>
      <LanguageBar makeUniversalSearch={makeUniversalSearch} />
      <HeaderBar />
      {/* <Accessibility /> */}
      {children}
      <FooterBar />
    </>
  )
}

export function LayoutWithoutHeader({ children, makeUniversalSearch }) {
  return (
    <>
      <LanguageBar makeUniversalSearch={makeUniversalSearch} />
      {/* <Accessibility /> */}
      {children}
      <FooterBar />
    </>
  )
}
