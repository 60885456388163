import React, { useContext, useState, useEffect } from 'react'
import { Accessibility } from 'components/Accessibility';
// initialize the udpate context...
const SpeechContextProvider = React.createContext();
const SpeechUpdateContextProvider = React.createContext();
const SpeechChangedContext = React.createContext();
const SpeechChangedUpdateContext = React.createContext();

// export the update context as a react hook...
export const useSpeechText = () => useContext(SpeechContextProvider);
export const useSpeechUpdateText = () => useContext(SpeechUpdateContextProvider);

export const useSpeechChangedText = () => useContext(SpeechChangedContext);
export const useSpeechChangedUpdateText = () => useContext(SpeechChangedUpdateContext);

export const SpeechTextContext = ({ children }) => {
    // Set initial variables (states)...
    const [speechText, setSpeechText] = useState('Welcome to the Enterprise Search engine for the United Nations. Please use the input box to enter your search.');
    const [speechTextChanged, setSpeechTextChanged] = useState(false);

    useEffect(() => {
        // Once the speech Text changes, reset the readout..
        setSpeechTextChanged(true);
        // console.log('Readout refreshed within context');

    }, []);

    useEffect(() => {
        // Once the speech Text changes, reset the readout..
        setSpeechTextChanged(true);
        // console.log('Readout reset within context');

    }, [speechText])

    return (
        <SpeechChangedContext.Provider value={speechTextChanged}>
            <SpeechChangedUpdateContext.Provider value={setSpeechTextChanged}>
                <SpeechContextProvider.Provider value={speechText}>
                    <SpeechUpdateContextProvider.Provider value={setSpeechText}>
                        <Accessibility speechText={speechText} speechTextChanged={speechTextChanged} setSpeechTextChanged={setSpeechTextChanged} />
                        {children}
                    </SpeechUpdateContextProvider.Provider>
                </SpeechContextProvider.Provider>
            </SpeechChangedUpdateContext.Provider>
        </SpeechChangedContext.Provider>

    )
}
