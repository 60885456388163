import React, { useState } from 'react'
import SearchBar from 'components/Search/SearchBar'
import { useTranslation } from 'react-i18next'
import Body from '../layouts/Body'
import { LayoutWithoutHeader } from 'layouts'


const Home = () => {
  const { t } = useTranslation();
  const [, setResetOAJPeriod] = useState(false);
  const [selectedItem, setSelectedItem] = useState('')
  const [initiateSearch, setInitiateSearch] = useState(false);

  return (
    <>
      <LayoutWithoutHeader>
        <Body t={t} setSelectedItem={setSelectedItem} setInitiateSearch={setInitiateSearch}>
          <section className="center">
            <SearchBar
              setResetOAJPeriod={setResetOAJPeriod}
              selectedSearchTerm={selectedItem}
              initiateSearch={initiateSearch}
              setInitiateSearch={setInitiateSearch}
            />
          </section>
        </Body>
      </LayoutWithoutHeader>
    </>
  )
}

export default Home
