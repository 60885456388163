import React, { useState, useLayoutEffect} from "react";
import { t, getCurrentLanguage } from "../../i18n/config"
import './HeaderSmall.css';

export const HeaderSmall = () => {
    const [homeLink, setHomeLink] = useState('');

    useLayoutEffect(() => {
        // Get the proper banner link...
        const urlBreaks = window.location.href.split("/");
        setHomeLink(urlBreaks[0] + "//" + urlBreaks[2]);

    }, [])

    return (
        <div className="header-container">
            <div className={`header ${getCurrentLanguage()}`}>
                <div className="header-left">
                    <a href={homeLink}>
                        <img src="./un-logo.png" alt="UN-Logo" />
                        <div className={`un-caption ${getCurrentLanguage()}`}>
                            <h2>{t('united')}</h2>
                            {getCurrentLanguage() === 'ru' && <h2>{t('united_2')}</h2>}
                            {getCurrentLanguage().substring(0, 2) !== 'zh' && <h2>{t('nations')}</h2>}
                        </div>
                    </a>
                    <a href={homeLink} className={`slogan ${getCurrentLanguage()}`}>
                        <span>Unite {t('search')}</span>
                        <span>{t('welcomeToEnterprise')}</span>
                    </a>
                </div>
            </div>
        </div>
    )
}
