import LoginWithGoogle from 'components/LoginWithSocial/Google'
import Layout from 'layouts'
import React from 'react'
import { LOCALSTORAGE } from 'utils/localstorage'
import './SignIn.css'

export default function SignIn() {

  const onLoginSuccess = (data) => {
    LOCALSTORAGE.store('accessToken', data.accessToken)
  }

  const onLoginFailure = (data) => {
    
  }

  return (
    <Layout>
      <section className="main-container sign-in-container">
        <h1 className="page-header">Account Login</h1>
        <form id="user-login" className="user-login" acceptCharset="UTF-8">
          <div className="form-item form-item-name form-type-textfield form-group">
            <label className="control-label" htmlFor="unite-id">
              Enter your Unite Id
            </label>
            <input
              className="form-control form-text required"
              data-toggle="tooltip"
              type="text"
              id="unite-id"
              name="unite-id"
              value=""
              data-original-title="Enter your UniteId "
            />
          </div>
          <div className="form-item form-item-pass form-type-password form-group">
            <label className="control-label" htmlFor="edit-pass">
              Password
            </label>
            <input
              className="form-control form-text required"
              title=""
              data-toggle="tooltip"
              type="password"
              id="password"
              name="password"
              data-original-title="Enter your UniteId password"
            />
          </div>
          <button type="submit" name="op" className="login-button">
            <span
              className="icon glyphicon glyphicon-log-in"
              aria-hidden="true"
            ></span>
            Log in
          </button>
          <h2>Continue with following options</h2>
          <div className="social-login">
            <LoginWithGoogle
              onSuccess={onLoginSuccess}
              onFailure={onLoginFailure}
            />
            <button>
              <i className="fa fa-facebook" />
            </button>
            <button>
              <i className="fa fa-twitter" />
            </button>
          </div>
        </form>
      </section>
    </Layout>
  )
}
