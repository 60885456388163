import React, { createContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

export const LanguageSelectionContext = createContext();

function LanguageSelectionProvider({ children }) {
  const userLanguage = localStorage.getItem('userLanguage') ? localStorage.getItem('userLanguage') : 'en'
  const [selectedLanguage, setLanguage] = useState(userLanguage)
  const { i18n } = useTranslation();

  const handleSelectLanguage = (lang) => {
    i18n.changeLanguage(lang)
    document.body.dir = i18n.dir()
    if (lang === 'ar') {
      document.body.style.fontFamily = 'Droid Arabic Kufi,serif'
    }
    localStorage.setItem('userLanguage', lang)
    setLanguage(lang);

  }

  useEffect(() => {
    if (selectedLanguage === 'ar') {
      document.body.dir = i18n.dir()
      document.body.style.fontFamily = 'Droid Arabic Kufi,serif'
    }
  }, [selectedLanguage])

  return (
    <LanguageSelectionContext.Provider value={{ selectedLanguage, handleSelectLanguage }}>
      {children}
    </LanguageSelectionContext.Provider>
  )
}

export default LanguageSelectionProvider
