//const apiUrl = process.env.REACT_APP_API_URL
const apiUrl = window.location.origin == 'http://localhost:3000'
  ? 'http://localhost:4000/api'
  : window.location.origin + process.env.REACT_APP_API_URL;

export const getCSRFToken = async () => {
  return { csrfToken: '' }
}

const parseAndFetchAPI = async (url, showLoader) => {
  let data, status;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'public, max-age=0',
      'X-CSRF-Token': sessionStorage.getItem('csrfToken') || '-',
      'withCredentials': true,
    }
  }

  try {

    showLoader(true);

    // Fetch the API..
    const getData = await fetch(url, options);
    const response = await getData.json();

    showLoader(false);

    data = response;
    status = getData.status;

    sessionStorage.setItem('csrfToken', response?.token);

    return { data, status };

  } catch (error) {
    // Issue occured...
    showLoader(false);
    return { data: error, status: false }
  }
}

export const SearchEndPoint = async (query, CSRFToken, showLoader) => {

  const URL = `${apiUrl}/search?${query}`;
  const { data, status } = await parseAndFetchAPI(URL, showLoader);

  return { data, status }
}

export const SearchEndPointSuggestions = async (query) => {

  const showLoader = () => console.log('Fetching suggestions...')
  const URL = `${apiUrl}/search/suggestions?${query}`;
  const { data, status } = await parseAndFetchAPI(URL, showLoader);

  return { data, status }
}

export const SearchWordCloudSuggestions = async () => {

  const showLoader = () => console.log('Fetching wc suggestions...');
  const URL = `${apiUrl}/wordcloud`
  const { data, status } = await parseAndFetchAPI(URL, showLoader);

  return { data, status }
}
