import React, { useState, useEffect, useRef } from 'react';
import useClickOutside from '../../hooks/useClickOutside';
import { getCurrentLanguage } from '../../i18n/config';
import './Accessibility.css';
import OutsideClick from 'hooks/clickOutside';
import { t } from '../../i18n/config';


export const Accessibility = ({ speechText, speechTextChanged, setSpeechTextChanged, prefLang = getCurrentLanguage() }) => {
    // Handles the accessibility settings for the site...
    const FILTER_OPTIONS = {
        none: 'none',
        greyScale: 'grey-scale',
        colorContrast: 'color-contrast'
    }

    const [zoomedIn, setZoomedIn] = useState(false);
    const [contrastFlyoutOpen, setContrastFlyoutOpen] = useState(false);
    const [toggleAudioFlyout, setToggleAudioFlyout] = useState(false);

    const flyoutRef = useRef();
    const audioFlyoutRef = useRef();

    const currentLanguage = getCurrentLanguage();

    const [selectedFliter, setSelectedFilter] = useState();

    const [speechSpeed,] = useState(1);
    const [speechIcon,] = useState('bx bx-volume-full');
    const [playIcon, setPlayIcon] = useState('icofont-play-alt-1');

    useEffect(() => {
        const options = JSON.parse(localStorage.getItem('accessibilityControl')) || {
            changeColorContrast: false,
            grayScale: false,
            allowScreenMagnification: false,
        }

        if (options.changeColorContrast) {
            setSelectedFilter(FILTER_OPTIONS.colorContrast);
        } else if (options.grayScale) {
            setSelectedFilter(FILTER_OPTIONS.greyScale);
        } else {
            setSelectedFilter(FILTER_OPTIONS.none);
        }

        setZoomedIn(options.allowScreenMagnification);

        // Always reset the speech...
        if (speechSynthesis.speaking) {
            speechSynthesis.cancel();
            setPlayIcon('icofont-play-alt-1');
        }

        setSpeechTextChanged(false);

    }, []);

    useEffect(() => {
        stopAudio();

    }, [speechTextChanged])

    const [initialState,] = useState(JSON.parse(localStorage.getItem('accessibilityControl')) || {
        changeColorContrast: false,
        grayScale: false,
        allowScreenMagnification: false,
    })

    const [accessibilityControl, setAccessibilityControl] = useState(initialState);

    const onChangeColorContrast = () => {
        setAccessibilityControl((prevState) => ({
            ...prevState, changeColorContrast: true, grayScale: false
        }))

        setSelectedFilter(FILTER_OPTIONS.colorContrast)
        setContrastFlyoutOpen(false);
    }

    const onChangeGrayScale = () => {
        setAccessibilityControl((prevState) => ({ ...prevState, grayScale: true, changeColorContrast: false }))
        setSelectedFilter(FILTER_OPTIONS.greyScale);
        setContrastFlyoutOpen(false);
    }

    const removeFilter = () => {
        setAccessibilityControl((prevState) => ({ ...prevState, grayScale: false, changeColorContrast: false }))
        setSelectedFilter(FILTER_OPTIONS.none);
        setContrastFlyoutOpen(false);
    }

    const playText = () => {
        // Plays the text sent to the player... speechText...
        const utterance = new SpeechSynthesisUtterance(speechText);

        // available voices to match current, expected language...
        const voices = window.speechSynthesis.getVoices().filter(voice => voice.lang?.toLowerCase().includes(prefLang));

        // console.table(window.speechSynthesis.getVoices());

        utterance.voice = voices[0];
        utterance.rate = speechSpeed || 1;
        utterance.lang = voices[0]?.lang;

        // Go ahead and speak!
        speechSynthesis.speak(utterance);

        // stop when the text is done...
        utterance.addEventListener('end', e => {
            setPlayIcon('icofont-play-alt-1');
            // console.log('Utterance completed after speaking for ' + e.elapsedTime + ' seconds');
        })
    }

    const handlePlayClicked = () => {
        // Always checking the status of the play...
        if (speechSynthesis.speaking && !speechSynthesis.paused) {
            speechSynthesis.pause();
            setPlayIcon('icofont-play-alt-1');
        } else if (speechSynthesis.speaking && speechSynthesis.paused) {
            speechSynthesis.resume();
            setPlayIcon('icofont-play-pause');
        } else {
            playText();
            setPlayIcon('icofont-play-pause');
        }
    }

    const stopAudio = function () {
        speechSynthesis.cancel();
        setPlayIcon('icofont-play-alt-1');
    }

    useEffect(() => {
        // Adjust contrast...
        if (accessibilityControl?.changeColorContrast) {
            document.documentElement.style.setProperty('filter', 'invert(100%)');
            document.documentElement.style.setProperty('background-color', 'white')
        }
        else if (accessibilityControl?.grayScale) {
            // Set to greyscale...
            document.documentElement.style.setProperty('filter', 'grayscale(100%)')
            document.documentElement.style.setProperty('background-color', 'white')
        } else {
            // Remove all filters...
            document.documentElement.style.setProperty('filter', 'none');
        }

        // Save present settings in localStorage...
        const parsedObj = JSON.stringify(accessibilityControl)
        localStorage.setItem('accessibilityControl', parsedObj)

    }, [accessibilityControl])

    useEffect(() => {
        if (zoomedIn) {
            // Set the accessibility to 130%...
            document.querySelector('body').classList.add('access-zoom');
            document.querySelector('body').style.transitions = '0.3s ease-in-out';

        } else {
            // Set the accessibility to 100%...
            document.querySelector('body').classList.remove('access-zoom');
        }

        setAccessibilityControl((prevState) => ({ ...prevState, allowScreenMagnification: zoomedIn }));
        localStorage.setItem('accessibilityControl', JSON.stringify({ ...accessibilityControl, allowScreenMagnification: zoomedIn }));

    }, [zoomedIn]);

    return (
        <div className={currentLanguage === 'ar' ? 'acc-container-ar' : 'acc-container'} tabIndex={0}>
            <div
                className="acc-contrast"
                title={t('accessibility')}
                tabIndex={1}
                onKeyDown={e => {
                    if (e.key === 'Enter' || e.key === 'Space') {
                        // Enter or space-bar...
                        // Toggle the flyout...
                        setContrastFlyoutOpen(!contrastFlyoutOpen);

                        // Also set the focus on the flyout...
                        flyoutRef.current.focus();
                    }
                }}>
                <OutsideClick onOutsideClick={() => setContrastFlyoutOpen(false)}>
                    <div className="acc-contrast-button" onClick={() => setContrastFlyoutOpen(!contrastFlyoutOpen)}>
                        {/* Displayed on the main page by default */}
                        <i className="ion-ios-body" aria-hidden="true" />
                    </div>
                    <div className={contrastFlyoutOpen ? 'acc-contrast-flyout open' : 'acc-contrast-flyout'}>
                        {/* Contains the 3 buttons... None, color invert and greyscale */}
                        <div
                            className={selectedFliter === FILTER_OPTIONS.none ? 'active' : ''}
                            onClick={removeFilter}
                            title={t('default')}
                            tabIndex={2}
                            onKeyDown={e => (e.key === 'Space' || e.key === 'Enter') && removeFilter()}
                        >
                            <i className='ion-contrast' />
                        </div>
                        <div
                            className={selectedFliter === FILTER_OPTIONS.colorContrast ? 'active' : ''}
                            onClick={onChangeColorContrast}
                            title={t('high_contrast')}
                            tabIndex={3}
                            onKeyDown={e => {
                                if (e.key === 'Space' || e.key === 'Enter') {
                                    onChangeColorContrast()
                                }
                            }}
                        >
                            <i className='ion-android-color-palette' />
                        </div>
                        <div
                            className={selectedFliter === FILTER_OPTIONS.greyScale ? 'active' : ''}
                            onClick={onChangeGrayScale}
                            title={t('greyscale')}
                            tabIndex={4}
                            onKeyDown={e => (e.key === 'Space' || e.key === 'Enter') && onChangeGrayScale()}
                        >
                            <i className='icofont-eye-alt' />
                        </div>
                    </div>
                </OutsideClick>
            </div>
            <div
                className="acc-zoom"
                onClick={() => setZoomedIn(!zoomedIn)}
                title={zoomedIn ? 'Zoom Out' : 'Zoom In'}
                tabIndex={5}
                onKeyDown={e => {
                    if (e.key === 'Enter' || e.key === 'Space') {
                        setZoomedIn(!zoomedIn)
                    }
                }}
                style={{ transform: 'translateY(-2px)' }}
            >
                <i className={!zoomedIn ? 'bx bx-zoom-in' : 'bx bx-zoom-out'} />
            </div>
            <OutsideClick onOutsideClick={() => setToggleAudioFlyout(false)}>
                <div
                    className='acc-zoom mt-half bg-other-color'
                    onClick={() => {
                        if (!toggleAudioFlyout && !speechSynthesis.speaking) {
                            setPlayIcon('icofont-play-pause');
                            playText();
                        }
                        setToggleAudioFlyout(!toggleAudioFlyout)
                    }}
                    title={t('read_out')}
                    tabIndex={6}
                    onKeyDown={e => (e.key === 'Space' || e.key === 'Enter') && handlePlayClicked()}
                >
                    <i className={speechIcon} style={{ color: 'var(--un-blue)' }} />
                </div>
                <div className={'acc-audio-flyout ' + (toggleAudioFlyout ? ('active ' + getCurrentLanguage()) : getCurrentLanguage())} ref={audioFlyoutRef}>
                    <div
                        className='audio-play-pause-button audio-btn'
                        onClick={handlePlayClicked}
                        tabIndex={7}>
                        <i className={playIcon} />
                    </div>
                    <div
                        className='audio-stop-button audio-btn'
                        onClick={stopAudio}
                        tabIndex={8}>
                        <i className='icofont-stop' />
                    </div>
                </div>
            </OutsideClick>
        </div>
    )
}
