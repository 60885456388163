import React from 'react'
import PropTypes from 'prop-types'
import { ResultItem } from './ResultItem'

export function ResultList({
  docs,
  collection,
  searchedTerm,
  showPdfViewer,
  pdfViewerIsVisible,
  setPdfUrl,
  pdfUrl
}) {

  return (
    <div style={{ width: '100%' }}>
      <CurrentItemToShow
        key={docs.id}
        currentItems={docs}
        collection={collection}
        searchedTerm={searchedTerm}
        showPdfViewer={showPdfViewer}
        pdfViewerIsVisible={pdfViewerIsVisible}
        setPdfUrl={setPdfUrl}
        pdfUrl={pdfUrl}
      />
    </div>
  )
}

function CurrentItemToShow({
  currentItems,
  collection,
  searchedTerm,
  showPdfViewer,
  pdfViewerIsVisible,
  setPdfUrl,
  pdfUrl
}) {

  const setPDFUrl = (url) => {
    if (!pdfViewerIsVisible) showPdfViewer()
    setPdfUrl(url)
  }

  return (
    <>
      {currentItems?.length > 0 ? currentItems.map((doc) => (
        <ResultItem
          key={doc.id}
          doc={doc}
          searchedTerm={searchedTerm}
          collection={collection}
          setPDFUrl={() => setPDFUrl(doc.url)}
          url={pdfUrl}
        />
      )
      ) : <></>}
    </>
  )
}

ResultItem.prototype = {
  docs: PropTypes.array,
}

CurrentItemToShow.prototype = {
  currentItems: PropTypes.array,
}
