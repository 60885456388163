import React, { createContext, useState, useEffect } from 'react'

export const UserPersonalizationContext = createContext()

const initialState = {
  itemsToShowPerPage: 10
}

function UserPersonalizationProvider({ children }) {
  const [userPersonalization, setUserPersonalization] = useState({
    ...initialState
  })

  const updateUserPersonalizedData = (key, value) => {
    setUserPersonalization((prevState) => ({ ...prevState, [key]: value }))
    sessionStorage.setItem(key, value)
  }

  useEffect(() => {
    const itemsPerPage = sessionStorage.getItem('itemsToShowPerPage') ? sessionStorage.getItem('itemsToShowPerPage') : 10
    updateUserPersonalizedData('itemsToShowPerPage', itemsPerPage)
  }, [])

  return (
    <UserPersonalizationContext.Provider value={{ userPersonalization, updateUserPersonalizedData }}>
      {children}
    </UserPersonalizationContext.Provider>
  )
}

export default UserPersonalizationProvider
