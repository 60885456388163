import React from "react";
import { t, getCurrentLanguage } from "i18n/config";
import './footer.css'

export const FooterBar = () => {
    const dummyText = getCurrentLanguage() !== 'none' ? '.' : '';
    return (
        <div className={'footer-container'}>
            <div className={`footer ${getCurrentLanguage()}`}>
                <div className="upper-footer">
                    <a href="https://www.un.org/en/" target="_blank">
                        <img src='/sepia-un-logo.png' alt="UN-Logo-footer" className="footer-logo" />
                        <div className="un-caption">
                            <h2>{t('united')}</h2>
                            {getCurrentLanguage() === 'ru' && <h2>{t('united_2')}</h2>}
                            {getCurrentLanguage().substring(0, 2) !== 'zh' && <h2>{t('nations')}</h2>}
                        </div>
                    </a>
                    <div className={`footer-social ${getCurrentLanguage()}`}>
                        <div className="social-links">
                            <a href="https://www.facebook.com/unitednations" target="_blank"><i className="bx bxl-facebook-square" /><span style={{ visibility: 'hidden' }}>{dummyText}</span> </a>
                            <a href="https://twitter.com/un" target="_blank"><i className="icofont-twitter" /><span style={{ visibility: 'hidden' }}>{dummyText}</span> </a>
                            <a href="https://www.youtube.com/unitednations" target="_blank"><i className="ion-social-youtube" /><span style={{ visibility: 'hidden' }}>{dummyText}</span> </a>
                            <a href="https://www.flickr.com/photos/un_photo/" target="_blank"><i className="bx bxl-flickr-square" /><span style={{ visibility: 'hidden' }}>{dummyText}</span> </a>
                            <a href="https://instagram.com/unitednations" target="_blank"><i className="icofont-instagram" /><span style={{ visibility: 'hidden' }}>{dummyText}</span> </a>
                        </div>
                        <div className="donate-button">
                            <a
                                href="https://www.un.org/en/about-us/how-to-donate-to-the-un-system"
                                className="btn btn-donate"
                                target="_blank"
                            >{t('donate')}</a>
                        </div>
                    </div>
                </div>
                <div className="bottom-links">
                    <a href={`https://www.un.org/${getCurrentLanguage().substring(0,2)}/site-index`} target="_blank">{t('site_index')}</a>|
                    <a href={`https://www.un.org/${getCurrentLanguage().substring(0,2)}/contact-us-0`} target="_blank">{t('contact')}</a>|
                    <a href={`https://www.un.org/${getCurrentLanguage().substring(0,2)}/about-us/copyright`} target="_blank">{t('copyRight')}</a>|
                    <a href={`https://www.un.org/${getCurrentLanguage().substring(0,2)}/about-us/frequently-asked-questions`} target="_blank">{t('faq')}</a>|
                    <a href={`https://www.un.org/${getCurrentLanguage().substring(0,2)}/about-us/fraud-alert`} target="_blank">{t('fraudAlert')}</a>|
                    <a href={`https://www.un.org/${getCurrentLanguage().substring(0,2)}/about-us/privacy-notice`} target="_blank">{t('privacyNotice')}</a>|
                    <a href={`https://www.un.org/${getCurrentLanguage().substring(0,2)}/about-us/terms-of-use`} target="_blank">{t('termsOfUse')}</a>
                </div>
            </div>
        </div>
    )
}
