import React, { useState, useEffect, useRef } from 'react'
import './jsmind.css';

const ReactJSmind = ({ mind, nodesCollection, setSymbol }) => {
    const [options] = useState({
        container: 'jsmind_container',
            editable: false,
            theme: 'refexplorer',
            support_html: true,
            view: {
                draggable: false,
                hide_scrollbars_when_draggable: true
            },
            focus: false,
            clickable: false,
    })

    const jmRef = useRef();
    const opt = { ...options };
    const isFirst = useRef(1);

    useEffect(() => {
        if (nodesCollection.length > 0) {
            loadMindMap();
        }
    }, [mind, nodesCollection])

    const loadMindMap = () => {
        if (isFirst.current == 1) {
            const jm = new jsMind(opt);
            jm.show(mind);
            isFirst.current = 0;

        } else {
            jmRef.current = jsMind.current;
            jmRef.current.show(mind);
        }

        // This function should fire when some variables are udpated...
        nodesCollection?.length > 0 && nodesCollection?.forEach(node => {
            // Get the node and add the click event...
            const foundNode = document.getElementById(node.nodeId);
            if (foundNode) {
                foundNode.onclick = () => {
                    setSymbol(node.symbol);
                }
            }
        })
    }

    return (
        <div id="jsmind_container"
            aria-hidden={true}
            style={{ width: '1400px', height: '90vh', zoom: '80%' }}
        ></div>
    )
}

export default ReactJSmind;
