import React from 'react'
import GoogleLogin from 'react-google-login'

export default function LoginWithGoogle({ onFailure, onSuccess }) {
  
  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy={'single_host_origin'}
      buttonText="Log in with Google"
    >
    </GoogleLogin>
  )
}