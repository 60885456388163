import React from 'react'
import { formatNumberWithCommas } from 'utils/numbers'
import './SearchResult.css'
import { useTranslation } from 'react-i18next';
import { getCurrentLanguage } from 'i18n/config';

export default function SearchResultStats({
  totalDocsFound,
  queryTimeSpent,
  searchKeyword,
  pageStartsAt,
  row,
  currentPageNumber,
}) {
  const { t } = useTranslation()
  pageStartsAt = pageStartsAt % 10 === 0 ? pageStartsAt / 10 : pageStartsAt

  const pageEndsAt =
    totalDocsFound / row > currentPageNumber
      ? currentPageNumber * row
      : totalDocsFound

  const _totalDocsFound = formatNumberWithCommas(totalDocsFound)
  return (
    <section className={"result-stats-container pt-2 pb-2 " + getCurrentLanguage()}>
      <p>
        {totalDocsFound > 0 && (
          <>
            {t('results')}{' '}
            <strong>
              {pageStartsAt} - {pageEndsAt}{' '}
            </strong>
            {t('of')} <strong>{_totalDocsFound}</strong> {t('for')}{' '}
            {searchKeyword}.{' '}
          </>
        )}
        {t('searchTook')} <strong>{queryTimeSpent}</strong> {t('seconds')}
      </p>
    </section>
  )
}
