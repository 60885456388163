const refineBy = [
  {
    value: 'news.un.org',
  },
  {
    value: '/ga',
  },
  {
    value: '/sc',
  },
  {
    value: '/ecosoc',
  },
  {
    value: '/decolonization',
  },
  {
    value: '/icj',
  },
]

const functionalCommission = [
  {
    value: '/ccpcj',
  },
  {
    value: '/cnd',
  },
  {
    value: '/stat',
  },
  {
    value: '/women',
  },
  {
    value: '/sustainabledevelopment',
  },
  {
    value: '/forests',
  },
]


const languageCode = [
  { value: 'ar' },
  { value: 'zh-cn' },
  { value: 'en' },
  { value: 'fr' },
  { value: 'ru' },
  { value: 'es' },
  { value: 'other' },
]




export function un() {
  return {
    refineBy,
    functionalCommission,
    languageCode,
  }
}
