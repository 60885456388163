import React, { useEffect, useState, useContext, useRef } from 'react'
import './SearchBar.css'
import SearchCollection from 'components/Search/SearchCollection'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Dropdown from 'components/Dropdown'
import queryString from 'query-string'
import { SearchEndPointSuggestions } from 'services/search'
import SearchSuggestions from '../SearchSuggestion'
import { CSRFTokenContext } from 'context/CSRFToken'
import { t } from 'i18next'
import { getCurrentLanguage } from 'i18n/config'
import { useSpeechChangedUpdateText } from 'context/SpeechTextContext'

function SearchBar({
  initialSearchValue,
  reloadPage,
  fromSeachResultPage = false,
  onSearch,
  closePdfViewer,
  itemsToShowPerPage = 10,
  setSearchParentValue,
  searchParentValue,
  selectedSearchTerm,
  initiateSearch,
  setInitiateSearch
}) {
  const [selectedCollection, setSelectedCollection] = useState('All')
  const [searchedSuggestion, setSearchedSuggestion] = useState([])
  const [searchValue, setSearchValue] = useState(initialSearchValue || '')
  const [searchParams, setSearchParams] = useSearchParams()
  const [fetchedData, setFetchedData] = useState([])
  const [isOpenDropDown, setOpenDropDown] = useState(false)
  const inputRef = useRef();

  const setSpeechTextChanged = useSpeechChangedUpdateText();


  const parsedQueries = queryString.parse(location.search)
  const { CSRFToken } = useContext(CSRFTokenContext)

  const toggleDropdown = () => {
    setOpenDropDown(!isOpenDropDown)
  }

  const closeDropdown = () => setOpenDropDown(false)

  const navigate = useNavigate()

  const getSearchSuggestions = async (value) => {
    const query = `q=${value}&collection=${selectedCollection}`
    const response = await SearchEndPointSuggestions(query, CSRFToken)
    setFetchedData(response.data)
  }

  useEffect(() => {
    const collection = searchParams.get('collection')
    if (collection) setSelectedCollection(collection)

  }, [searchParams])

  const onChangeHandler = async (value) => {
    setSearchValue(value);

    // Set the search queryString as the current value...
    parsedQueries.q = value;

    // Display search suggestions as a dropdown menu, once the
    // length exceeds 4 characters...
    if (value.length >= 5) {
      await getSearchSuggestions(value)
      const dataMatchExactWord = fetchedData.filter(({ term }) => !term.indexOf(value)).slice(0, 5);
      setSearchedSuggestion(dataMatchExactWord);
    } else {
      setSearchedSuggestion([]);
      setFetchedData([]);
    }

    // Remove the OAJ pdfViewer variable...
    sessionStorage.setItem('pdfViewerIsVisible', '');
  }

  const clearSuggestions = () => {
    setSearchedSuggestion([])
  }

  const onSelectSearchTerm = async (term) => {
    setSearchValue(term)
    setSearchParams({
      ...parsedQueries,
      q: term,
    })
    closePdfViewer()
    await onSearch(term)
    clearSuggestions()
  }

  useEffect(() => {
    if (searchParentValue) {
      // This means the search was clicked on the parent container/component
      // Thus, set the value of q to the current search value...
      setSearchValue(searchParams.get('q'));
      setSearchParentValue(false);
    }

    if (selectedSearchTerm) {
      setSearchValue(selectedSearchTerm)
    }

    if (initiateSearch) {
      onClickSearch();
      setInitiateSearch(false);
    }

  }, [searchParentValue, selectedSearchTerm, initiateSearch]);

  const handleSelectCollection = (collection) => {
    setSelectedCollection(collection)
    const q = searchParams.get('q') ? searchParams.get('q') : '*'

    setSearchParams({ q, collection })
    if (closePdfViewer) {
      closePdfViewer()
    }

    if (reloadPage) {
      location.reload();

      // If the page is reloaded, reset the searchValue...
      setSearchValue(q);
    }

    // Stop the readout...
    setSpeechTextChanged(true);
  }

  const handleSearch = (e) => {
    const keyword = searchValue ? searchValue : '*';

    clearSuggestions()

    e.preventDefault();

    if (searchValue === '' || searchValue === null || searchValue === undefined) {
      setSearchValue('*')
    }

    setSearchParams({
      ...parsedQueries,
      sort: parsedQueries.sort ? parsedQueries.sort : 'relevance',
      q: searchValue,
    })

    if (fromSeachResultPage) {
      onSearch(searchValue)
      return
    }

    const sort = parsedQueries.sort ? parsedQueries.sort : 'relevance';
    const row = parsedQueries.row ? parsedQueries.row : itemsToShowPerPage;

    navigate({
      pathname: '/search',
      search: `?q=${keyword}&collection=${selectedCollection}&row=${row}&sort=${sort}`,
    })

  }

  const resetSearchKeyword = () => {
    inputRef.current.focus();
    setSearchValue('')

    setSearchParams({
      ...parsedQueries,
      q: '*'
    })
    // inputRef.current.focus()
  }

  const onClickSearch = () => {

    // Stop the readout...
    setSpeechTextChanged(true);

    if (!searchValue) setSearchValue('*')

    const keyword = searchValue ? searchValue : '*'
    const sort = parsedQueries.sort ? parsedQueries.sort : 'relevance'
    const row = parsedQueries.row ? parsedQueries.row : itemsToShowPerPage;

    navigate({
      pathname: '/search',
      search: `?q=${keyword}&collection=${selectedCollection}&row=${row}&sort=${sort}`,
    });

    // Also set the actual search parameter to the search field...
    setSearchValue(parsedQueries.q);

    if (onSearch) {
      onSearch(searchValue)
    }

    // Remove the OAJ pdfViewer variable...
    sessionStorage.setItem('pdfViewerIsVisible', '');

    // Clear search suggestions...
    clearSuggestions();

  }

  const smallWidth = window.location.href.indexOf('search?') < 1 ? 'small-width' : '';

  return (
    <div className="search-bar-container">
      {/* Search input field */}
      <form className="search-form" onSubmit={handleSearch}>
        <section className="input-field-container">
          <div className={'search-dropdown-container ' + getCurrentLanguage()}>
            <Dropdown
              isOpen={isOpenDropDown}
              toggleDropdown={toggleDropdown}
              selectedItem={selectedCollection === 'All' ? t('all') : selectedCollection.toUpperCase()}
              closeDropdown={closeDropdown}
              DropdownContent={() => (
                <SearchCollection
                  selectedCategory={selectedCollection}
                  handleSelectCollection={handleSelectCollection}
                  closeDropdown={closeDropdown}
                />
              )}
              showIcon
              showSelectedItem
              parent={"SearchBar"}
            />
          </div>
          <input
            type="text"
            className={`input-field ${getCurrentLanguage()}`}
            aria-label='Search box'
            onChange={(e) => onChangeHandler(e.target.value)}
            ref={inputRef}
            onBlur={() => {
              setTimeout(() => {
                clearSuggestions()
              }, 1000)
            }}
            value={searchValue}
            placeholder={t('search')}
            title={t('search_title')}
          />
          {searchedSuggestion.length >= 1 && (
            <SearchSuggestions
              searchedSuggestions={searchedSuggestion}
              onSelectSearchTerm={onSelectSearchTerm}
            />
          )}
          <div className={"buttons-icon-container " + getCurrentLanguage() + " " + smallWidth}>
            <button
              className="search-button"
              aria-label='Clear search result'
              style={searchValue.length >= 1 ? { opacity: 1 } : { opacity: 0 }}
              type="button"
              onClick={resetSearchKeyword}
              title={t('cancel')}
            >
              <i className="icofont-close" aria-hidden="true" style={{ height: '16cm' }}></i>
            </button>
            <button
              className="search-button"
              style={window.location.href.indexOf('search?') < 1 ? { display: 'none' } : { display: 'block' }}
              aria-label='Search'
              type="button"
              onClick={onClickSearch}
              title={t('search')}
            >
              <i className="icofont-search" aria-hidden="true" style={{ height: '16cm' }}></i>
            </button>
            <a
              className="search-button" aria-label='Help'
              href="/assets/Unite_Search_Query_Guide.pdf"
              target="_blank"
              style={{ color: '#333!important' }}
              title={t('help')}
            >
              <i className="icofont-question" aria-hidden="true" style={{ height: '16cm', color: 'var(--doc-black)' }}></i>
            </a>
          </div>
        </section>
      </form>
    </div>
  )
}

export default SearchBar
