import React, { useEffect, useState, useContext } from 'react'
import { formatFilterQueryData } from 'utils/formatFieldQuery'
import { useTranslation } from 'react-i18next'
import { FilterQueryAccordion } from './FilterQueryAccordion'
import queryString from 'query-string'
import { LanguageSelectionContext } from 'context/languageLocalization'

const FilterOptionButtons = ({ resetFilters, applyFilter }) => {
  const { t } = useTranslation('fieldQuery')

  return (
    <div className="reset-buttons">
      <button className="btn blue_button"
        aria-label={t('applyFilter')}
        onClick={applyFilter}>
        {t('applyFilter')}
      </button>
      <button className="btn blue_button" aria-label={t('resetFilterQuery')} onClick={resetFilters}>
        {t('resetFilterQuery')}
      </button>
    </div>
  )
}

export default function FilterQuery({ collection, resetFilters, showFilterMenu, onSearch }) {
  const parsedQueries = queryString.parse(location.search)
  const { selectedLanguage } = useContext(LanguageSelectionContext)

  const [selectedFilterQueries, setSelectedFilterQueries] = useState(parsedQueries)
  const data = formatFilterQueryData(collection)

  //console.log(' location search => ' + JSON.stringify(parsedQueries));

  const applyFilter = () => {
    // Apply the filter on click..
    //console.log('parsequery ==> ' + JSON.stringify(parsedQueries));
    //setSelectedFilterQueries(parsedQueries)
    selectedFilterQueries.sort = parsedQueries.sort;
    //console.log('selected = ' + JSON.stringify(selectedFilterQueries));
    onSearch(parsedQueries.q, selectedFilterQueries)
    
  }

  useEffect(() => {
    // Update the query selection when the language selection changes...
    //console.log('before if language changed => ' + JSON.stringify(parsedQueries) + ' selected language => ' + selectedLanguage + ' selected queries => ' + JSON.stringify(selectedFilterQueries));
    if (parsedQueries.collection === 'rep' && (!parsedQueries.languageCode || parsedQueries.languageCode === undefined)) {
      parsedQueries.languageCode = selectedLanguage
    }
    
    // Update...
    //parsedQueries.languageCode = selectedFilterQueries.languageCode;
    setSelectedFilterQueries(parsedQueries)
    //parsedQueries.languageCode = selectedFilterQueries.languageCode;
    //console.log('after if language changed => ' + JSON.stringify(parsedQueries) + ' selected language => ' + selectedLanguage + ' selected queries => ' + JSON.stringify(selectedFilterQueries));

  }, [parsedQueries.languageCode])


  return (
    <section className="facet-container">
      <FilterOptionButtons resetFilters={resetFilters} applyFilter={applyFilter} />
      {Object.entries(data).map(([filterQueryName, filterQueryValues]) => (
        <FilterQueryAccordion
          filterQueryName={filterQueryName}
          filterQueryValues={filterQueryValues}
          key={filterQueryName}
          showFilterMenu={showFilterMenu}
          selectedFilterQueries={selectedFilterQueries}
          setSelectedFilterQueries={setSelectedFilterQueries}
        />
      ))}
    </section>
  )
}
