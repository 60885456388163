import React from 'react'
import loader from 'assets/images/loader.gif'
import './Loader.css';

function Loader() {
  return (
    <div className='loader-container'>
      <img src={loader} className='loader-animatio' alt='loading' />
    </div>
  )
}

export default Loader