import React, { useState, useRef, useEffect, useContext } from 'react'
import { SearchBar } from 'components/Search'
import SearchResultStats from 'components/Search/Result/ResultStat'
import { ResultList } from 'components/Search/Result/ResultList'
import { createSearchParams, useSearchParams } from 'react-router-dom'
import { SearchEndPoint } from 'services/search'
import NoSearchResult from 'components/Search/Result/NoSearchResult'
import Layout from 'layouts'
import './SearchResult.css'
import Facet from 'components/Search/Result/FacetAndFilterQuery/Facet'
import FilterQuery from 'components/Search/Result/FacetAndFilterQuery/FilterQuery'
import queryString from 'query-string'
import DocListInPdfViewer from 'components/Search/Result/DocListInPdfViewer'
import Pagination from 'components/Search/Result/Pagination'
import SortDocuments from 'components/Search/Result/SortDocuments'
import { CSRFTokenContext } from 'context/CSRFToken'
import { Loader } from 'components/Loader'
import ItemsPerPage from 'components/Search/Result/Pagination/ItemsPerPage'
import { LanguageSelectionContext } from 'context/languageLocalization'
import { UserPersonalizationContext } from 'context/personalization'
import ExportResult from 'components/Search/Result/ExportResult'
import { useSpeechUpdateText } from 'context/SpeechTextContext'
import { t, getCurrentLanguage } from 'i18n/config'

export default function SearchResult() {
  const parsedQueries = queryString.parse(location.search);
  const setSpeechText = useSpeechUpdateText();

  const { userPersonalization:
    { itemsToShowPerPage }, updateUserPersonalizedData } = useContext(UserPersonalizationContext)

  parsedQueries.currentPageNumber = parseInt(parsedQueries.currentPageNumber) > 0
    && parseInt(parsedQueries.currentPageNumber) <= parseInt(localStorage.getItem('pcnt'))
    ? parseInt(parsedQueries.currentPageNumber)
    : 1;

  const [tp,] = useState(sessionStorage.getItem('tp'));

  const [itemOffset, setItemOffset] = useState(tp)
  const [currentPageNumber, setCurrentPageNumber] = useState(parsedQueries.currentPageNumber)
  const [searchResultData, setSearchResultData] = useState({})
  const [loaded, setLoaded] = useState(false)
  const [showFilters, setShowFilters] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const { selectedLanguage } = useContext(LanguageSelectionContext)
  const [searchParentValue, setSearchParentValue] = useState(false);

  const [isDownloading, setIsDownloading] = useState(false)
  const [pdfViewerIsVisible, setPdfViewerIsVisible] = useState(sessionStorage.getItem('pdfViewerIsVisible') || false);
  const [pdfUrl, setPdfUrl] = useState('')
  const collection = searchParams.get('collection')
  const currLanguageCode = searchParams.get('languageCode')
  let setLanguageCode = null;

  const [pageCount, setPageCount] = useState(1)
  const { CSRFToken } = useContext(CSRFTokenContext)
  const searchContainerRef = useRef(null)

  const prevCurrentPage = useRef(null);
  const prevItemsPerPage = useRef(null);
  const prevLanguage = useRef(null);

  const onChangeItemsToShow = (val) => {
    const value = parseInt(val)
    updateUserPersonalizedData('itemsToShowPerPage', value)
    setCurrentPageNumber(1)
  }

  const onSearch = async (searchValue, selectedOptions) => {

    // Set the parameters ready for the database search api call...
    // Always cleae the search...
    console.log('line 74 search >>>>>')

    let obj = {}
    obj = parsedQueries
    if (collection === 'rep' && (!obj.languageCode || obj.languageCode === undefined)) {
      obj.languageCode = selectedLanguage
    }

    if (collection === 'rep') {
      if (selectedLanguage === 'zh') {
        obj.languageCode = 'zh-cn'
      } else {
        obj.languageCode = selectedLanguage
      }
    }

    // Clear out any  places where there are no values...
    if (obj.fromYear === '' || parsedQueries.fromYear === '') {
      //console.log('Deleting the from year key and value...');
      delete obj.fromYear;
      delete parsedQueries.fromYear;
    }

    if (obj.toYear === '' || parsedQueries.toYear === '') {
      //console.log('Deleting the to year key and value...')
      delete obj.toYear;
      delete parsedQueries.toYear;
    }

    if (obj.dateFrom === '' || parsedQueries.dateFrom === '') {
      //console.log('Deleting the from date key and value...')
      delete obj.dateFrom;
      delete parsedQueries.dateFrom;
    }

    if (obj.dateTo === '' || parsedQueries.dateTo === '') {
      //console.log('Deleting the to date key and value...')
      delete obj.dateTo;
      delete parsedQueries.dateTo;
    }

    setCurrentPageNumber(1)
    setSearchParams({
      ...{
        sort: parsedQueries.sort ? parsedQueries.sort : 'relevance',
        ...obj,
        q: searchValue || '*',
        row: itemsToShowPerPage,
        ...selectedOptions
      }
    })

    if (selectedOptions) {
      obj = { ...selectedOptions }
    }

    sessionStorage.setItem('tp', 1);

    // Initialize the search fields...
    obj.currentPageNumber = 1
    obj.row = itemsToShowPerPage
    obj.q = searchValue || '*'

    parsedQueries.sort = parsedQueries.sort ? parsedQueries.sort : 'relevance'

    console.log('......createSearchParams', createSearchParams(obj).toString());
    const { data, status } = await SearchEndPoint(
      `${createSearchParams(obj)}`,
      CSRFToken, setIsLoading
    )

    // validate and obtain results...
    if (status === 200) {
      setSearchResultData(data)
      onSetPageCount(data.numberDocsFound)
      setSpeech(data);

      if (collection === 'oaj' || collection === 'rep') {
        setPdfViewerIsVisible(sessionStorage.getItem('pdfViewerIsVisible'));

        // Check to ensure that item is visible or not...
        if (sessionStorage.getItem('pdfViewerIsVisible')) {
          const thisURL = Array.isArray(data.docs[0].url) ? data.docs[0].url[0] : data.docs[0].url;
          setPdfUrl(thisURL);
        }
      }

    } else {
      // Failed status...
      if (data.message.indexOf('Timeout') > -1) {
        // Timed out...
        alert('Search timed out! Try again after some time...')
      }
      setSearchResultData([]);
      onSetPageCount(0);

      setSpeechText('Welcome to the Enterprise Search engine for the United Nations. Please use the input box to enter your search.');
    }

    // Consider collapsing if there is no result...
    if (data.numberDocsFound === 0) {
      setShowFilters(false);
    } else {
      setShowFilters(true);
    }

    setLoaded(true)
    setPdfViewerIsVisible(sessionStorage.getItem('pdfViewerIsVisible') || false);

    // Remember to set the text back to the main search field...
    setSearchParentValue(true);
  }

  const getCurrentPageNumber = () => {
    if (currentPageNumber === 1) {
      return 1
    } else if (parseInt(sessionStorage.getItem('tp')) < 1) {
      sessionStorage.setItem('tp', 1);
      setCurrentPageNumber(1)
      return 1;
    } else if (parseInt(tp) % parseInt(itemsToShowPerPage) !== 1) {
      setCurrentPageNumber(1);
      return 1;
    } else {
      return (parseInt(currentPageNumber) - 1) * parseInt(itemsToShowPerPage) + 1
    }
  }

  const searchPage = async () => {
    // debug
    setPdfViewerIsVisible(false);

    if (collection === 'rep' && !parsedQueries.languageCode && parsedQueries.languageCode === undefined) {
      parsedQueries.languageCode = selectedLanguage
    }

    if (collection === 'rep') {
      if (selectedLanguage === 'zh') {
        parsedQueries.languageCode = 'zh-cn'
      } else {
        parsedQueries.languageCode = selectedLanguage
      }
    }

    if (currLanguageCode !== null && currLanguageCode.includes(',')) {
      setLanguageCode = currLanguageCode;
    } else if (parsedQueries.languageCode !== null && parsedQueries.languageCode !== undefined) {
      setLanguageCode = parsedQueries.languageCode;
    } else {
      setLanguageCode = selectedLanguage;
    }

    if (collection === 'rep') {
      parsedQueries.languageCode = setLanguageCode;
    }

    const currRow = [10, 25, 50, 100].indexOf(parseInt(parsedQueries.row)) !== -1 ? parsedQueries.row : itemsToShowPerPage;
    //if (itemsToShowPerPage != currRow) onChangeItemsToShow(currRow);

    setSearchParams({
      sort: parsedQueries.sort ? parsedQueries.sort : 'relevance',
      ...parsedQueries,
      row: itemsToShowPerPage, //currRow,
      currentPageNumber
    });

    parsedQueries.row = itemsToShowPerPage
    parsedQueries.sort = parsedQueries.sort ? parsedQueries.sort : 'relevance'

    // Just in case the currentPageNumber is not as expected...
    // const pnValue = Math.ceil(sessionStorage.getItem('td') / itemsToShowPerPage)

    // setCurrentPageNumber(pnValue);

    const { data, status } = await SearchEndPoint(
      `${createSearchParams({
        ...parsedQueries,
        mLen: localStorage.getItem('pcnt') || 1,
        td: localStorage.getItem('td') || 10,
      })}`,
      CSRFToken, setIsLoading
    )

    if (status === 200) {
      // Check...
      setSearchResultData(data)
      onSetPageCount(data.numberDocsFound)

      setSpeech(data);

      if (collection === 'oaj' || collection === 'rep') {
        setPdfViewerIsVisible(sessionStorage.getItem('pdfViewerIsVisible'));

        // Check to ensure that item is visible or not...
        if (sessionStorage.getItem('pdfViewerIsVisible')) {
          const thisURL = Array.isArray(data.docs[0].url) ? data.docs[0].url[0] : data.docs[0].url;
          setPdfUrl(thisURL);
        }
      }

    } else {
      // Can be 500 or so...
      setSearchResultData([]);
      onSetPageCount(0);

      // Set to the default value...
      setSpeechText('Welcome to the Enterprise Search engine for the United Nations. Please use the input box to enter your search.');
    }

    // Consider collapsing if there is no result...
    if (data.numberDocsFound === 0) {
      setShowFilters(false);
    } else {
      if (parsedQueries.collection != 'All') {
        setShowFilters(true);
      } else {
        setShowFilters(false);
      }
    }

    setLoaded(true);
  }

  const setSpeech = data => {
    const collection = parsedQueries.collection.toLowerCase();
    const collValue = (collection === 'all' || collection === 'un') ? t(collection) : t(collection + '_only');

    const currentPageNumber = parseInt(parsedQueries.currentPageNumber);
    const totalFound = parseInt(data?.numberDocsFound) || 0;
    const rowCount = parseInt(parsedQueries.row);


    const itemCount = rowCount;

    // Also check for the last page number...

    const currentItem = 1 + ((currentPageNumber - 1) * rowCount);
    const secondItem = Math.min(currentItem + itemCount - 1, totalFound);

    const criteriaText = t('search_results_text')
      .replace('$a', currentItem)
      .replace('$b', secondItem)
      .replace('$c', totalFound)
      .replace('$d', parsedQueries?.q || '')
      .replace('$e', collValue);


    // let initialText = t('result_details').replace('<a>', totalFound).replace('<b>', collValue) + '\n\n';
    let initialText = criteriaText + '\n\n';
    initialText += 'Page ' + currentPageNumber + '.\n\n';

    data?.docs?.forEach((item, index) => {
      initialText += 'item ' + (index + currentItem) + '.\n\n';
      initialText += setTextToSpeech(item);
    })

    initialText += "\n\n\n. " + t('thanks');

    setSpeechText(initialText);
  }

  const setTextToSpeech = input => {
    // set output based on the collection type...
    const judgesString = `${t('jugdes')}: ${input['jugdes']} \n`;
    const collection = input['Data Source'].toLowerCase();
    const collValue = (collection.substring(0, 2) === 'un')
      ? t('un')
      : (collection === 'all')
        ? t('all')
        : t(collection + '_only');

    switch (parsedQueries.collection.toUpperCase()) {
      case 'ALL':
        return (`${t('title')}: ${input['title']} \n
          ${t('Data Source')}: ${collValue} \n\n`);

      case 'UN': case 'DESA':
        return (`${t('title')}: ${input['title']} \n
          ${t('language')}: ${t((input['language']).toLowerCase())} \n\n`);

      case 'OAJ':
        return (`${t('title')}: ${input['title']} \n
          ${(input['jugdes'] != null) ? judgesString : ''}
          ${t('language')}: ${t((input['language']).toLowerCase())} \n\n`);

      case 'ODS':
        return (`${t('symbol')}: ${input['symbol']} \n
          ${t('title')}: ${input['title']} \n
          ${t('Publication Date')}: ${input['Publication Date']} \n
          ${t('language')}: ${t((input['language']).toLowerCase())} \n\n`);

      case 'REP':
        const subtitle = (input['subtitle']) ? `${t('subtitle')}: ${input['subtitle']}` : '';

        return (`${t('title')}: ${input['title']} \n
          ${subtitle} \n
          ${t('Chapters / Parts')}: ${input['Chapters / Parts']} \n
          ${t('year')}: ${input['year']} \n
          ${t('supplement')}: ${input['supplement']} \n\n`);

    }
  }

  const onSetPageCount = (totalDocsFound) => {
    setPageCount(Math.ceil(totalDocsFound / itemsToShowPerPage))
  }

  const resetFilters = () => {
    const { q } = parsedQueries
    setSearchParams({
      collection,
       // tp: getCurrentPageNumber(),currentPageNumber,
      row: itemsToShowPerPage,
      currentPageNumber: currentPageNumber,
      q
    });

    sessionStorage.setItem('tp', getCurrentPageNumber());
    location.reload()
  }

  const toggleShowFilters = () => {
    setShowFilters((prevState) => !prevState)
    if (!showFilters) sessionStorage.setItem('showFilters', false)
  }

  useEffect(() => {
    parsedQueries.currentPageNumber = parsedQueries.currentPageNumber || 1;

    if ((prevCurrentPage.current == currentPageNumber) && (prevItemsPerPage.current == itemsToShowPerPage) && (prevLanguage.current == selectedLanguage)) {
      // do nothing
    } else {
      setSearchParams({
        languageCode: parsedQueries.languageCode
      })
      searchPage();
      prevCurrentPage.current = currentPageNumber;
      prevItemsPerPage.current = itemsToShowPerPage;
      prevLanguage.current = selectedLanguage;
    }

  }, [currentPageNumber, itemsToShowPerPage, selectedLanguage])

  const executeScrollToTop = () => searchContainerRef.current.scrollIntoView()

  const showPdfViewer = (val) => {
    setPdfViewerIsVisible(val);
    sessionStorage.setItem('pdfViewerIsVisible', val ? 'true' : '');
  }

  const onClickBackButton = () => {
    setPdfViewerIsVisible(false)
    sessionStorage.setItem('pdfViewerIsVisible', '');
    setShowFilters(false)
  }

  useEffect(() => {
    const defaultQueryParams = ['collection',
      'q',
      'row',
      'sort',
      'currentPageNumber'
    ]

    Object.keys(parsedQueries).map((val) => {
      if (!defaultQueryParams.includes(val) && parsedQueries[val]) {
        showFilterMenu()
      }
    })

  }, [selectedLanguage]);

  useEffect(() => {

    if (searchResultData?.docs?.length === 0) {
      let outputText = '';
      outputText += t('yourSearch') + '\n ' + t('suggestions') + '\n';

      [1, 2, 3].map(val => {
        outputText += t(`didNotMatchSuggestion.${val}`) + '\n'
      })

      setSpeechText(outputText);
    } else

      // Once there are search results, check and/or update the query parameters stored within the localStorage...
      if (parsedQueries.currentPageNumber == 1 && sessionStorage.getItem('tp') == 1) {
        // Lets check the collection and query string...
        localStorage.setItem('pcnt', Math.ceil(searchResultData.numberDocsFound / parsedQueries.row));
        localStorage.setItem('td', searchResultData.numberDocsFound);
      }

    // if (localStorage.getItem('q') !== parsedQueries.q && localStorage.getItem('coll') === parsedQueries.collection) {
    //   localStorage.setItem('q', parsedQueries.q);
    //   localStorage.setItem('tdocs', searchResultData.numberDocsFound);
    // } else {
    //   // if the q is the same as the stored...

    // }

  }, [searchResultData])

  const showFilterMenu = () => {
    setPdfViewerIsVisible(false)
    sessionStorage.setItem('pdfViewerIsVisible', '');
    setShowFilters(true)
  }

  const JSONToCSVConvertor = async (JSONData, ReportTitle, ShowLabel) => {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    const arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData;
    let CSV = '';

    //This condition will generate the Label/Header
    if (ShowLabel) {
      let row = '';

      //This loop will extract the label from 1st index of on array
      for (let index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += index + ',';
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + '\r\n';
    }

    //1st loop is to extract each row
    for (let i = 0; i < arrData?.length; i++) {
      let row = '';
      //2nd loop will extract each column and convert it in string comma-seprated
      for (const index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + '\r\n';
    }

    if (CSV === '') {
      return;
    }

    //Generate a file name
    //this will remove the blank-spaces from the title and replace it with an underscore
    const fileName = ReportTitle.replace(/ /g, '_');

    //Initialize file format you want csv or xls
    // var csvContent = 'data:text/csv;charset=utf-8,' + encodeURI(CSV);


    //this trick will generate a temp <a /> tag
    let link = document.createElement('a');
    const blob = new Blob(['\ufeff', CSV]);

    link.href = URL.createObjectURL(blob);

    //set the visibility hidden so it will not effect on your web-layout
    link.style = 'visibility:hidden';
    link.download = fileName + '.csv';

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    await link.click();
    document.body.removeChild(link);
    setIsDownloading(false)
  };

  const exportResult = async () => {
    setIsDownloading(true)
    // parsedQueries.tp = 1
    parsedQueries.row = searchResultData?.numberDocsFound < 500 ? searchResultData?.numberDocsFound : 500
    parsedQueries.exportResult = true;

    const { data, status } = await SearchEndPoint(
      `${createSearchParams({ ...parsedQueries })}`,
      CSRFToken, setIsLoading
    )

    // Check if the query was successful...
    if (status === 200) {
      await JSONToCSVConvertor(data?.docs, 'download_search_data', true)
    }
  }

  const onPageChange = (page) => {
    setCurrentPageNumber(page)
    //console.log('parsed queries => ' + JSON.stringify(parsedQueries));
    setSearchParams({
      ...parsedQueries,
      currentPageNumber: page
    })
    executeScrollToTop()

  }

  const closePdfViewer = () => {
    setPdfViewerIsVisible(false)
    sessionStorage.setItem('pdfViewerIsVisible', '');
  }

  const singleScreen = showFilters || pdfViewerIsVisible ? '' : 'single';
  const screenLHS = collection === 'oaj' ? (
    <Facet
      facets={searchResultData?.facet}
      resetFilters={resetFilters}
      onSearch={onSearch}
      showFacet={showFilters || pdfViewerIsVisible}
    />
  ) : (
    <FilterQuery
      collection={collection}
      resetFilters={resetFilters}
      onSearch={onSearch}
    />
  )

  return (

    <Layout >
      <section className="center">
        <SearchBar
          initialSearchValue={searchParams.get('q')}
          reloadPage
          hideWelcomeText
          onSearch={onSearch}
          closePdfViewer={closePdfViewer}
          fromSeachResultPage={true}
          selectedLanguage={selectedLanguage}
          itemsToShowPerPage={itemsToShowPerPage}
          setSearchParentValue={setSearchParentValue}
          searchParentValue={searchParentValue}
        />
      </section>
      {isLoading && (
        <section className="center" style={{ height: '0vh' }}>
          <Loader />
        </section>
      )}
      <div className='main-outside-container'>
        <div className="main-container" ref={searchContainerRef}>
          {loaded && (
            <>
              <div className="result-stat-section ">
                <div className='filter-menu-btn'>
                  {
                    collection !== 'All' &&
                    <span className={"dark-blue-text " + getCurrentLanguage()} onClick={pdfViewerIsVisible ? onClickBackButton : toggleShowFilters} >
                      {
                        (showFilters || pdfViewerIsVisible) ?
                          <i className='home-icon fa fa-arrow-left' ></i>
                          :
                          <i className='home-icon fa fa-bars'> </i>
                      }
                    </span>
                  }
                  <SearchResultStats
                    totalDocsFound={searchResultData?.numberDocsFound || 0}
                    queryTimeSpent={searchResultData?.queryTime || 0}
                    searchKeyword={searchParams.get('q')}
                    pageStartsAt={(parseInt(parsedQueries.currentPageNumber) - 1) * parseInt(parsedQueries.row) + 1 || 1}
                    row={searchParams.get('row')}
                    currentPageNumber={currentPageNumber}
                    clearFilters={resetFilters}
                  />
                </div>
                <div className='sort-by-container'>
                  <SortDocuments
                    onSearch={onSearch}
                  />
                  <ItemsPerPage
                    onChangeItemsToShow={onChangeItemsToShow}
                    itemsPerPage={itemsToShowPerPage}
                    onSearch={onSearch}
                  />
                </div>
              </div>
              {pdfViewerIsVisible ? (
                <DocListInPdfViewer
                  docs={searchResultData?.docs}
                  collection={searchParams.get('collection')}
                  searchedTerm={searchParams.get('q')}
                  pdfViewerIsVisible={pdfViewerIsVisible}
                  showPdfViewer={() => showPdfViewer(true)}
                  pdfUrl={pdfUrl}
                  setPdfUrl={setPdfUrl}
                />
              ) : (
                <>
                  <div className={`facet-result-list-container ${parsedQueries.collection?.toLowerCase()} ${singleScreen}`}>
                    {/* <div className="d-flex flex-grow-1"> */}
                    {collection !== 'All' && showFilters && (
                      <div className="d-lg-flex d-md-flex facet-list">
                        {screenLHS}
                      </div>
                    )}
                    {searchResultData?.docs?.length > 0 ? (
                      <ResultList
                        docs={searchResultData?.docs}
                        collection={searchParams.get('collection')}
                        totalDocsFound={searchResultData?.numberDocsFound}
                        itemsPerPage={itemsToShowPerPage}
                        onChangeItemsToShow={onChangeItemsToShow}
                        itemOffset={itemOffset}
                        setItemOffset={setItemOffset}
                        onSearch={onSearch}
                        setCurrentPageNumber={setCurrentPageNumber}
                        executeScrollToTop={executeScrollToTop}
                        searchedTerm={searchParams.get('q')}
                        pdfViewerIsVisible={pdfViewerIsVisible}
                        showPdfViewer={() => showPdfViewer(true)}
                        pdfUrl={pdfUrl}
                        setPdfUrl={setPdfUrl}
                      />
                    ) : (
                      <NoSearchResult
                        searchKeyword={searchParams.get('q')}
                      />
                    )}
                    {/* </div> */}
                  </div>
                </>
              )}
            </>
          )}
          {loaded && searchResultData?.docs?.length > 0 && (
            <>
              <Pagination
                totalCount={searchResultData?.numberDocsFound}
                pageSize={itemsToShowPerPage}
                onChangeItemsToShow={onChangeItemsToShow}
                itemOffset={itemOffset}
                setItemOffset={setItemOffset}
                onPageChange={onPageChange}
                currentPage={currentPageNumber === 0 ? 1 : currentPageNumber}
                executeScrollToTop={executeScrollToTop}
                pageCount={pageCount}
                setPageCount={onSetPageCount}
                showPdfViewer={() => showPdfViewer(sessionStorage.getItem('pdfViewerIsVisible'))}
              />
              <ExportResult
                totalDocsFound={searchResultData?.numberDocsFound}
                exportResult={exportResult}
                isDownloading={isDownloading}
              />
            </>
          )}
          {!loaded && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '90px 0' }}>
            <i className='d-none bx bx-loader bx-spin bx-flip-horizontal' style={{ fontSize: '3em', color: 'var(--link-blue)' }}></i>
          </div>}
        </div>
      </div>
    </Layout>
  )
}
