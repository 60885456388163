import React, { useState, useEffect } from 'react'
import './Body.css'
import { MultiSelect } from 'components/multi-select';
import { HeaderSmall } from 'layouts/HeaderSmall';

const apiUrl = window.location.origin == 'http://localhost:3000' ? 'http://localhost:4000/api' : window.location.origin + process.env.REACT_APP_API_URL

function Body({ t, children, setInitiateSearch }) {

  const [visibleMostSearched, setVisibleMostSearched] = useState(false);
  const [data, setData] = useState([]);

  // useEffect(() => {
  //   // Import the wordcloud data each  we get to the default search page...
  //   const options = {
  //     method: 'GET',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //       'withCredentials': true,
  //     }
  //   }

  //   fetch(apiUrl + '/wordcloud', options)
  //     .then(res => (res.json()))
  //     .then(response => {
  //       // Set the response...
  //       if (response && response.status === 200) {
  //         // Good to go...
  //         load(response.data);
  //         setData(response.data);
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     })

  //   // eslint-disable-next-line
  // }, []);

  function load(data) {
    const tagCanvas = document.getElementById('wc_container');
    const wc_list = data;

    WordCloud(tagCanvas, { list: wc_list, classes: "tag-cloud-item", });
    tagCanvas.addEventListener('wordcloudstop', (e) => {
      document.querySelectorAll('.tag-cloud-item').forEach(function (element) {
        const text = element.innerHTML;
        element.innerHTML = `<a href="/search?sort=relevance&collection=All&q=${text}&row=10&currentPageNumber=1" style="color: inherit;">${text}</a>`;
      });
    });
  }

  const chooseRandomWord = () => {
    // As the name implies...
    const upperBound = data.length;
    const index = Math.floor(Math.random() * upperBound);

    // setSelectedItem(data[index][0]);
  }

  // Ensure that the unitesearch@un.org email is displayed as a link...
  const frontPageMessage = () => {
    const output = t('frontPageMessage').replace('unitesearch@un.org', `<a href='mailto:unitesearch@un.org' target='_blank'>unitesearch@un.org</a>`);
    return output;
  }

  return (
    <>
      <div className="main-container container">
        <div className='home-page-center-panel'>
          <div style={{ display: 'none', width: '700px', height: '500px' }} id="wc_container"></div>
          <div className='header-small'>
            <HeaderSmall />
          </div>
          {children}
          <div className='home-page-link-buttons d-none'>
            <button
              className='body-text link'
              onClick={() => setVisibleMostSearched(true)}
            >{t('most_searched')}</button>
            <span className='vert-div'>|</span>
            <button
              className='body-text link'
              onClick={chooseRandomWord}
            >{t('random_search')}</button>
            <span className='vert-div'>|</span>
            <a href='/assets/Unite_Search_Query_Guide.pdf' target='_blank' className='body-text link'>Help</a>
          </div>
          <p className='body-text' dangerouslySetInnerHTML={{
            __html: frontPageMessage(),
          }}></p>
          <div className='body-text'>
            <button className='home-buttons' onClick={() => setInitiateSearch(true)}>{t('search')}</button>
            <span className='vert-div'></span>
            <a href='/assets/Unite_Search_Query_Guide.pdf' target='_blank' className='home-buttons'>{t('help')}</a>
          </div>

        </div>
      </div >
      {visibleMostSearched &&
        <MostSearchedDialog
          list={data}
          hideDialog={() => setVisibleMostSearched(false)}
          setSelectedItem={setSelectedItem}
        />}
    </>
  )
}

const MostSearchedDialog = ({ list, hideDialog, setSelectedItem }) => {
  return (
    <div className='modal-background'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h2><i className='bx bx-search' /> {t('most_searched_items')}</h2>
        </div>
        <div className='modal-body'>
          <div style={{ maxHeight: 450, overflowY: 'auto' }}>
            <p style={{ fontFamily: "'Roboto Condensed', sans-serif", color: 'var(--doc-black)', fontSize: '1.1em' }}>{t('most_searched_items_are')}</p>

            <MultiSelect
              inputList={list}
              setSelectedValue={setSelectedItem}
              closeDialog={hideDialog}
            />

          </div>
        </div>
        <div className='modal-footer'>
          <button
            className='body-text link'
            style={{ marginBottom: 0 }}
            onClick={hideDialog}>{t('cancel')}</button>
        </div>
      </div>
    </div>
  )
}

export default Body
