import CustomModal from 'components/Modal'
import { t } from 'i18n/config';
import React, { useEffect, useState } from 'react'
import { viewPlainText } from 'services/document'

export default function ViewPlainText(props) {
  const {
    id,
    handleClose,
    visible,
    collection,
    plainTextLanguage,
    setPlainTextLanguage,
    fullTextAvailableLanguages } = props;

  const [isFetchingData, setIsFetchingData] = useState(false)
  const [text, setText] = useState('');
  const [error, setError] = useState(false);
  const [languageCode, setLanguageCode] = useState('');
  const [dir, setDir] = useState('ltr');

  const getLanguageCode = () => {
    const languages = ['ar', 'zh', 'en', 'fr', 'ru', 'es'];
    for (const eachLanguage of languages) {
      if (id.indexOf(eachLanguage + '_') > -1 || id.indexOf('_' + eachLanguage) > -1) {
        setLanguageCode(eachLanguage);
        return;
      }
    }
  }

  const textFontFamily = languageCode === 'ar' ? 'Times New Roman' : '"Roboto Condensed", sans-serif';
  const bodyStyle = {
    // whiteSpace: 'break-spaces',
    // wordBreak: 'break-all',
    lineBreak: 'auto',
    whiteSpace: 'pre-wrap',
    fontSize: '1em',
    fontFamily: textFontFamily,
    lineHeight: '2em'
  }

  const getText = async () => {
    const { data, status } = await viewPlainText(id, collection, setIsFetchingData, plainTextLanguage)

    if (status === 200) {
      const output = data.data;

      // check if output is unusually long...
      // let acc = '';
      // let balanceText = output || '';

      // while (balanceText.length > 0) {
      //   const textWidth = document.querySelector('.modal-body').offsetWidth / 8.5 || 120;
      //   const balTextLength = balanceText.length >= textWidth ? textWidth : balanceText.length;

      //   acc += balanceText.substring(0, balTextLength) + '<br/>'
      //   balanceText = balanceText.split(balanceText.substring(0, balTextLength))[1];
      // }

      // // replace every occurence of chinese special characters with line break...
      // acc = acc.replace(/、/g, `、<br/>`);

      // setText(acc || output);
      setText(output);

      if (data.message.indexOf('Timeout') > -1) {
        setError('session_timeout');
      } else {
        setError(false);
      }
    } else {
      setText(t('error_occured'));
    }
  }

  useEffect(() => {
    if (visible) getText()
    getLanguageCode();

  }, [id, visible, plainTextLanguage])

  useEffect(() => {
    setDir((languageCode === 'ar' || plainTextLanguage === 'ar') ? 'rtl' : 'ltr');

  }, [languageCode, plainTextLanguage]);

  const LowerHeader = () => {
    return (<>
      <div className="alert alert-warning" role="alert" style={{ textAlign: 'center', fontSize: 12 }} >
        {t('errornous_message').split('$a')[0]} <a href="mailto:unitesearch@un.org?subject=ErroneousBody-E/CN.4/1999/106-TPL=ods">&nbsp;here&nbsp;</a> {t('errornous_message').split('$a')[1]}
      </div>
      <p style={{ fontSize: '1.1em', fontFamily: '"Roboto Condensed, sans-serif"', textAlign: 'center', color: 'maroon', width: '100%' }}>{error && error}</p>
      <p style={{ fontSize: '1.1em', fontFamily: '\'Roboto Condensed\', sans-serif', textAlign: 'center', fontWeight: 'bold', width: '100%' }}>{text === 404 && 'Plain text not available!'}</p>

    </>)
  }

  const txt = ` A/77/L.73  األمــم المتحـدة

  联合国

	A/77/L.73

	 [image: ]
	大  会

	Distr.: Limited
1 June 2023
Chinese
Original: English




	A/77/L.73





	A/77/L.73



	Comment by Start: <>N2315483C<>
<>A/77/L.73<>
<><>
	[image: ]23-10370 (C)    020623    060623
*2310370*
	[image: ]



	23-10370
	3/4



	2/4
	23-10370



	第七十七届会议



	议程项目31



	古阿姆集团地区旷日持久的冲突及其
对国际和平、安全与发展的影响





		阿尔巴尼亚、安道尔、安提瓜和巴布达、澳大利亚、奥地利、比利时、保加利亚、加拿大、哥斯达黎加、克罗地亚、捷克、丹麦、吉布提、爱沙尼亚、斐济、芬兰、法国、格鲁吉亚、德国、希腊、危地马拉、匈牙利、冰岛、爱尔兰、意大利、日本、基里巴斯、拉脱维亚、利比里亚、列支敦士登、立陶宛、卢森堡、马耳他、马绍尔群岛、密克罗尼西亚联邦、摩纳哥、黑山、荷兰(王国)、北马其顿、挪威、巴布亚新几内亚、波兰、葡萄牙、摩尔多瓦共和国、罗马尼亚、圣马力诺、斯洛伐克、斯洛文尼亚、西班牙、瑞典、汤加、土耳其、图瓦卢、乌克兰、大不列颠及北爱尔兰联合王国和美利坚合众国：* 决议草案
		格鲁吉亚阿布哈兹和格鲁吉亚茨欣瓦利地区/南奥塞梯境内流离失所者和难民的境况
	大会，
	*	提案国名单如有任何变化，将在会议正式记录中予以反映。
	回顾其有关保护和援助境内流离失所者的所有相关决议，其中包括2007年12月18日第62/153号、2008年5月15日第62/249号、2009年9月9日第63/307号、2009年12月18日第64/162号、2010年9月7日第64/296号、2011年6月29日第65/287号、2011年12月19日第66/165号、2012年7月3日第66/283号、2013年6月13日第67/268号、2013年12月18日第68/180号、2014年6月5日第68/274号、2015年6月3日第69/286号、2015年12月17日第70/165号、2016年6月7日第70/265号、2017年6月1日第71/290号、2017年12月19日第72/182号、2018年6月12日第72/280号、2019年6月4日第73/298号、2019年12月18日第74/160号、2020年9月3日第74/300号、2021年6月16日第75/285号、2021年12月16日第76/167号和2022年6月8日第76/267号决议，
	又回顾安全理事会述及所有各方必须努力实现全面和平并让境内流离失所者和难民返回原籍地的各项格鲁吉亚问题相关决议，并强调指出全面及时地执行这些决议的重要性，
	确认《关于境内流离失所问题的指导原则》是保护境内流离失所者的主要国际框架，[footnoteRef:1]  [1: 		E/CN.4/1998/53/Add.2，附件。]

	关切格鲁吉亚境内冲突造成强迫性人口结构变化，
	又关切2008年8月武装冲突引发的人道主义局势造成更多平民被迫流离失所，
	意识到亟需找到格鲁吉亚境内被迫流离失所问题的解决办法，
	着重指出2008年10月15日开始的日内瓦讨论意义重大，必须依照国际公认原则和解决冲突惯例，继续处理境内流离失所者和难民自愿、安全、有尊严、无阻碍地返回这一问题，
	表示注意到秘书长关于第76/267号决议执行情况的报告，[footnoteRef:2]  [2: 		A/77/870。]

	1.	确认，所有境内流离失所者和难民及其后裔无论族裔归属，都有权返回其在格鲁吉亚各地、包括在阿布哈兹和茨欣瓦利地区/南奥塞梯的家园；
	2.	强调指出必须尊重所有受格鲁吉亚境内冲突影响的境内流离失所者和难民的财产权，不得通过侵犯这些权利而获取财产；
	3.	重申强迫性人口结构变化是不可接受的；
	4.	着重指出迫切需要让人道主义活动不受阻碍地接触格鲁吉亚全境所有受冲突影响地区的所有境内流离失所者、难民和其他居民；
	5.	促请参与日内瓦讨论的各方加紧努力建立持久和平，致力于加强建立信任措施，并立即采取步骤，以确保尊重人权，为所有境内流离失所者和难民自愿、安全、有尊严、无阻碍地返回原籍地创造有利的安全环境；
	6.	着重指出需要制订一个时间表，以确保所有受格鲁吉亚境内冲突影响的境内流离失所者和难民自愿、安全、有尊严、无阻碍地返回家园；
	7.	请秘书长向大会第七十八届会议提交一份关于本决议执行情况的全面报告；
	8.	决定将题为“古阿姆集团地区旷日持久的冲突及其对国际和平、安全与发展的影响”的项目列入大会第七十八届会议临时议程。

image1.jpg

image2.gif

image3.png
 `

  return (
    <CustomModal
      isFetchingData={isFetchingData}
      handleClose={handleClose}
      visible={visible}
      header={t('preview')}
      plainTextLanguage={plainTextLanguage}
      setPlainTextLanguage={setPlainTextLanguage}
      fullTextAvailableLanguages={fullTextAvailableLanguages}
      dir={dir}
      LowerHeader={LowerHeader}
      lowerHeaderPresent={true}
    >
      <p
        dir={dir}
        style={bodyStyle}
        // className='result-description-text'
        dangerouslySetInnerHTML={{
          __html: text !== 404 && text // + txt
        }}
      />
    </CustomModal>
  )
}
