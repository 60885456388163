import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import LanguageSelectionProvider from 'context/languageLocalization';
import CSRFTokenProvider from 'context/CSRFToken'
import RenderRoutes from './routes';
import UserPersonalizationProvider from 'context/personalization';
import { SpeechTextContext } from 'context/SpeechTextContext';

function App() {
  return (
    <SpeechTextContext>
      <CSRFTokenProvider>
        <UserPersonalizationProvider>
          <LanguageSelectionProvider>
            <Router>
              <RenderRoutes />
            </Router>
          </LanguageSelectionProvider>
        </UserPersonalizationProvider>
      </CSRFTokenProvider>
    </SpeechTextContext>
  )
}

export default App
